import {signInWithEmailAndPassword, User} from "firebase/auth";
import {Admin} from '../interfaces';
import {auth} from "./firebaseApp";

// default value
interface DefaultValue {
  user: User | null;
  loading: boolean;
  role: string;
  roleTitle: string
}

const defaultValue: DefaultValue = {
  user: null,
  loading: false,
  role: "",
  roleTitle: "",

};

export const AuthenticationService = {

  async loginWithEmail(form: Admin) {
    const {email, password} = form;
    return await signInWithEmailAndPassword(auth, email, password);
  },

  async signOut() {
    await auth.signOut();
  }
}

// export default auth;
