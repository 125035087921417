import React, {FC, useEffect, useState} from "react";
import {Button, Divider, Grid, Stack, Typography} from "@mui/material";
import {useHistory} from "react-router-dom";
import DeleteClassDialog from "./DeleteClassDialog";
import {SideDetailsBox} from "../../../../utils/styledComponents";
import AddSessionDialog from "../../../../components/AddSessionDialog";
import ViewSessionDialog from "./ViewSessionDialog";
import {LiveClass} from "../../../../interfaces";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import SessionService from "../../../../services/session.service";

const EditClassButtons: FC<Props> = (props) => {
  const STRINGS = {
    ACTION_BUTTONS: "Action Buttons",
    UPDATE_CLASS: "Update Class",
    DELETE_CLASS: "Delete Class",
    BACK: "Back",
    SESSION: "Session",
    ADD_SESSION: "Add Session",
    VIEW_SESSION: "View Sessions",
    OK: "Okay",
    PLEASE_ADD_SESSION:
      "Please kindly add a new session for a freshly created live class.",
    HINT_UPDATE_CLASS: "Hint: Update the class"

  };

  const {liveClass, handleUpdate, handleDelete} = props;

  let history = useHistory();

  const [prompt, setPrompt] = useState<DialogTypes>("");
  const [showAddSession, setShowAddSession] = useState(false);

  const DialogComponent = () => {
    switch (prompt) {
      case "delete class":
        return (
          <DeleteClassDialog
            onClose={() => setPrompt("")}
            handleDelete={handleDelete}
          />
        );
      case "add session":
        return <AddSessionDialog handleClose={() => setPrompt("")}/>;
      case "view session":
        return (
          <ViewSessionDialog
            liveClass={liveClass}
            handleClose={() => setPrompt("")}
          />
        );
      default:
        return null;
    }
  };

  const checkSession = async () => {
    // check if there's sessions
    if (!liveClass.lcId) return;
    const sessionCounts = await SessionService.readAllSessionCount(
      liveClass.lcId
    );
    setShowAddSession(sessionCounts <= 0);
  };

  useEffect(() => {
    checkSession();
  }, [liveClass.lcId]);

  return (
    <Grid item xs={12} lg={3}>
      <Stack divider={<Divider/>}>
        <SideDetailsBox>
          <Typography variant="h5" sx={{my: "1rem"}}>
            {STRINGS.ACTION_BUTTONS}
          </Typography>
          <Stack spacing={3}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleUpdate}
            >
              {STRINGS.UPDATE_CLASS}
            </Button>
            <Typography variant="caption">
              {STRINGS.HINT_UPDATE_CLASS}
            </Typography>

            <Button
              variant="outlined"
              color="error"
              fullWidth
              onClick={() => setPrompt("delete class")}
            >
              {STRINGS.DELETE_CLASS}
            </Button>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push(`/live-class/${liveClass.lcId}`)}
            >
              {STRINGS.BACK}
            </Button>
          </Stack>
        </SideDetailsBox>

        <SideDetailsBox>
          <Typography variant="h5" sx={{my: "1rem"}}>
            {STRINGS.SESSION}
          </Typography>
          <Stack spacing={3}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => setPrompt("add session")}
            >
              {STRINGS.ADD_SESSION}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => setPrompt("view session")}
            >
              {STRINGS.VIEW_SESSION}
            </Button>
          </Stack>
        </SideDetailsBox>
      </Stack>

      <DialogComponent/>

      {/* remind add session dialog */}
      <GeneralDialogComponent
        open={showAddSession}
        onClose={() => setShowAddSession(false)}
        title={"Reminder"}
        actions={
          <Button
            color="primary"
            onClick={() => {
              setShowAddSession(false);
              setPrompt("add session");
            }}
          >
            {STRINGS.OK}
          </Button>
        }
      >
        <Typography variant="body1">{STRINGS.PLEASE_ADD_SESSION}</Typography>
      </GeneralDialogComponent>
    </Grid>
  );
};

export default EditClassButtons;

interface Props {
  handleUpdate: () => void;
  handleDelete: () => void;
  liveClass: LiveClass;
}

type DialogTypes =
  | "update class"
  | "delete class"
  | "add session"
  | "view session"
  | "";
