import {
  Autocomplete,
  Box,
  Button,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import React, {
  ChangeEvent,
  HTMLInputTypeAttribute,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import GeneralDialogComponent from "./GeneralDialogComponent";
import { REGEX } from "../constants/regex.constant";
import {
  MakeTextField,
  Session,
  SessionErrors,
  SessionKeys,
} from "../interfaces";
import {
  AutoCompleteOption,
  MakeTextFields,
} from "../interfaces/TextField.interface";
import LiveClassService from "../services/liveclass.service";
import SessionService from "../services/session.service";
import { convertTitleToId } from "../utils/convertString";
import { make } from "../utils/makeAutoCompleteOption";
import { useAuth } from "../utils/providers/AuthProvider";

import moment from "moment";
import { useSnackbar } from "notistack";
import { checkTextFieldError } from "../utils/checkTextField";

interface Props {
  handleClose: () => void;
}
const AddSessionDialog = (props: Props) => {
  const STRINGS = {
    ADD_SESSION: "Add Session",
    SUBMIT: "submit",
    SUCCESS_CREATE_SESSION: "Successfully created session!",
    FAILED_CREATE_SESSION: "Failed to create session!",
    INVALID_START_DATETIME: "Invalid Start Datetime",
    INVALID_END_DATETIME: "Invalid End Datetime",
    START_MUST_EARLIER: "Start Datetime must be earlier than End Datetime",
  };

  const { handleClose } = props;
  const { lcId }: any = useParams();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [form, setForm] = useState<Session>({} as Session);
  const [options, setOptions] =
    useState<AutoCompleteOptions>(autoCompleteOptions);
  const [errors, setErrors] = useState<SessionErrors>({} as SessionErrors);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const makeTextField = (
    label: string,
    type: HTMLInputTypeAttribute | "textarea" | "autocomplete",
    key: SessionKeys,
    pattern: RegExp,
    required: boolean = false,
    disabled: boolean = false
  ): MakeTextField<Session> => ({
    label,
    type: type === "autocomplete" ? "autocomplete" : type,
    key,
    pattern,
    required,
    disabled,
    value: form[key],
    error: errors[key],
    helperText: errors[key]
      ? required && !form[key]
        ? "This field is required"
        : `Invalid ${label}`
      : "",
    name: key,
    multiline: type === "textarea",
    rows: 4,
  });

  const textFields: MakeTextFields<Session> = {
    sessionId: makeTextField("", "text", "sessionId", REGEX.WORDS),
    sessionNumber: makeTextField("", "number", "sessionNumber", REGEX.NUMBER),
    teacherName: makeTextField("", "autocomplete", "teacherName", REGEX.NAME),
    teacherEmail: makeTextField("", "text", "teacherEmail", REGEX.EMAIL),
    groupTitle: makeTextField("", "text", "groupTitle", REGEX.WORDS),
    lpId: makeTextField("", "text", "lpId", REGEX.WORDS),
    lcId: makeTextField("Live Class", "text", "lcId", REGEX.WORDS, true, true),
    groupId: makeTextField("Group", "autocomplete", "groupId", REGEX.WORDS),
    createdAt: makeTextField("Created At", "text", "createdAt", REGEX.ANY),
    updatedAt: makeTextField("Updated At", "text", "updatedAt", REGEX.ANY),
    updatedBy: makeTextField("Updated By", "text", "updatedBy", REGEX.WORDS),
    totalSlots: makeTextField(
      "Total Slots",
      "number",
      "totalSlots",
      REGEX.NUMBER
    ),

    sessionTitle: makeTextField(
      "Session Title",
      "text",
      "sessionTitle",
      REGEX.ANY,
      true
    ),
    teacherId: makeTextField(
      "Teacher",
      "autocomplete",
      "teacherId",
      REGEX.WORDS,
      true
    ),
    sessionMaterial: makeTextField(
      "Material",
      "autocomplete",
      "sessionMaterial",
      REGEX.ANY
    ),
    meetingLink: makeTextField(
      "Meeting Button Link URL",
      "text",
      "meetingLink",
      REGEX.ANY
    ),
    sessionStartDateTs: makeTextField(
      "Start Datetime",
      "datetime-local",
      "sessionStartDateTs",
      REGEX.ANY,
      true
    ),
    sessionEndDateTs: makeTextField(
      "End Datetime",
      "datetime-local",
      "sessionEndDateTs",
      REGEX.ANY,
      true
    ),
    lcDetailHtml: makeTextField(
      "Live Class Details HTML",
      "autocomplete",
      "lcDetailHtml",
      REGEX.ANY
    ),
    sessionDetailHtml: makeTextField(
      "Session Details HTML",
      "textarea",
      "sessionDetailHtml",
      REGEX.ANY
    ),
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDisableSubmit(false);
    const { required, value } = e.target;
    const name = e.target.name as SessionKeys;
    setForm({ ...form, [name]: value });

    // this is to check if the text field has error
    // 1. if field is '', return TRUE if field is required
    // 2. if field has value, return TRUE if pattern is wrong
    const pattern = textFields[name].pattern;
    const error: boolean = !value
      ? required
      : !new RegExp(pattern)?.test(value);
    setErrors({ ...errors, [name]: error });
  };

  const handleAutoCompleteChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: AutoCompleteOption | null,
    name: SessionKeys
  ) => {
    e.preventDefault();
    setDisableSubmit(false);
    setForm({ ...form, [name]: newValue?.value });
  };

  const handleSubmit = async () => {
    const teacher = await LiveClassService.readOneTeacher(form.teacherId);

    if (!form.sessionStartDateTs) {
      return enqueueSnackbar(STRINGS.INVALID_START_DATETIME, {
        variant: "error",
      });
    }
    if (!form.sessionEndDateTs) {
      return enqueueSnackbar(STRINGS.INVALID_END_DATETIME, {
        variant: "error",
      });
    }

    const sessionStartDateTs = moment(form.sessionStartDateTs).valueOf();
    const sessionEndDateTs = moment(form.sessionEndDateTs).valueOf();
    if (sessionStartDateTs > sessionEndDateTs) {
      return enqueueSnackbar(STRINGS.START_MUST_EARLIER, {
        variant: "error",
      });
    }

    const massagedForm: Session = {
      ...form,
      sessionId: convertTitleToId(form.sessionTitle),
      sessionStartDateTs,
      sessionEndDateTs,
      teacherEmail: teacher.teacherEmail,
      teacherName: teacher.teacherName,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      updatedBy: user?.email || "",
    };

    // revalidate form
    const hasError = Object.values(massagedForm).some((textField) => {
      const { name, label } = textField;
      const error: boolean = checkTextFieldError(textField, form);
      if (error) {
        setDisableSubmit(false);
        setErrors({ ...errors, [name]: error });
        enqueueSnackbar(`Invalid ${label}`, { variant: "error" });
      }
      return error;
    });
    if (hasError) return;

    try {
      await SessionService.createSession(massagedForm);
      enqueueSnackbar(STRINGS.SUCCESS_CREATE_SESSION, {
        variant: "success",
      });
      return handleClose();
    } catch (error: any) {
      return enqueueSnackbar(error.message || STRINGS.FAILED_CREATE_SESSION, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    (async () => {
      // get dropdown options
      const lcDetailHtmls = await LiveClassService.readLiveClassesHtml();
      const teachers = await LiveClassService.readTeachers();
      const materials = await LiveClassService.readMaterials();
      setOptions({
        lcDetailHtml: lcDetailHtmls.map((html) =>
          make(html.lcTitle, html.lcDescHtml, html.lcDescHtml)
        ),
        teacherId: teachers.map((teacher) =>
          make(teacher.teacherName, teacher.teacherId, teacher.teacherEmail)
        ),
        sessionMaterial: materials.map((material) =>
          make(
            material.materialTitle,
            material.materialID,
            material.materialLinksHtml
          )
        ),
      });

      // populate text fields
      const liveClass = await LiveClassService.readOneLiveClass(lcId);
      const sessionCounts = await SessionService.readAllSessionCount(lcId);
      setForm({
        ...form,
        lcId,
        groupId: liveClass.lcUserGroup,
        sessionTitle: `${liveClass.lcTitle} Session ${sessionCounts + 1}`,
        lcDetailHtml: liveClass.lcDescHtml,
        teacherId: convertTitleToId(liveClass.teacherName),
        teacherEmail: liveClass.teacherEmail,
        teacherName: liveClass.teacherName,
      });
    })();
  }, []);

  return (
    <GeneralDialogComponent
      open
      onClose={handleClose}
      title={STRINGS.ADD_SESSION}
      actions={
        <Button
          variant="contained"
          color="primary"
          disabled={disableSubmit}
          onClick={handleSubmit}
        >
          {STRINGS.SUBMIT}
        </Button>
      }
    >
      {Object.values(textFields)
        .slice(12)
        .map((textField, index) =>
          textField.type === "autocomplete" ? (
            <Autocomplete
              fullWidth
              disabled={textField.disabled}
              options={options[textField.key as AutoCompleteOptionKeys]}
              getOptionLabel={(option) => option.title}
              renderOption={(props, option) => (
                <ListItem {...props} title={option.title}>
                  <ListItemText
                    primary={option.title}
                    secondary={option.subtitle || ""}
                  />
                </ListItem>
              )}
              isOptionEqualToValue={(option) =>
                option.value === form[textField.key]
              }
              value={options[textField.key as AutoCompleteOptionKeys].find(
                (option) => option.value === form[textField.key]
              )}
              onChange={(e, newValue) =>
                handleAutoCompleteChange(e, newValue, textField.name)
              }
              // random key needs to be used in order to rerender the autocomplete field after async event
              key={`autocomplete-${textField.key}-${Math.random() * 1000}`}
              renderInput={(params) => (
                <TextField margin="normal" {...params} {...textField} />
              )}
            />
          ) : (
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              {...textField}
            />
          )
        )}
    </GeneralDialogComponent>
  );
};

interface AutoCompleteOptions {
  lcDetailHtml: AutoCompleteOption[];
  teacherId: AutoCompleteOption[];
  sessionMaterial: AutoCompleteOption[];
}
type AutoCompleteOptionKeys = keyof AutoCompleteOptions;

const autoCompleteOptions: AutoCompleteOptions = {
  lcDetailHtml: [],
  teacherId: [],
  sessionMaterial: [],
};

export default AddSessionDialog;
