import React from "react";
import {Switch, useRouteMatch} from "react-router-dom";
import PrivateRoute from "../../layouts/Router/PrivateRoute";
import CoursePage from "./components";
import CourseProgress from "./CourseProgress";


const LiveClass = () => {
  const {path} = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path} component={CoursePage}/>

      <PrivateRoute
        exact
        path={`${path}/:courseId`}
        component={CourseProgress}
      />
      <PrivateRoute
        path={`${path}/edit-course/:courseId`}
        component={CoursePage}
      />
      <PrivateRoute
        path={`${path}/course-progress/:courseId`}
        component={CourseProgress}
      />
    </Switch>
  );
};

export default LiveClass;
