import {get, ref} from "firebase/database";
import {UserScore} from "../interfaces";
import {db} from "./firebaseApp";
import {CourseProgress} from "../interfaces/UserScore.interface";

export const ProgressService = {
  async getAllUserScores(): Promise<UserScore> {
    const scoresRef = ref(db, "/userScore");
    const snapshot = await get(scoresRef);
    return snapshot.val();
  },

  async getOneUserScore(userId: string, courseId: string): Promise<CourseProgress> {
    const scoresRef = ref(db, "/userScore/" + userId + "/" + courseId);
    const snapshot = await get(scoresRef);
    return snapshot.val();
  },
};
