import React, { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import BreadcrumbsComponent from "../../../components/Breadcrumbs";
import AppContainer from "../../../containers/AppContainer";
import { LiveClass, UserProgress } from "../../../interfaces";
import LiveClassService from "../../../services/liveclass.service";
import { ProgressService } from "../../../services/progress.service";
import Details from "./components/LiveClassDetail";
import Progress from "./components/Progress";

const LiveClassProgress = () => {
  const { url } = useRouteMatch();
  const { lcId }: any = useParams();

  const [liveClass, setLiveClass] = useState<LiveClass>({} as LiveClass);
  const [userProgress, setUserProgress] = useState<UserProgress[]>([]);

  const fetchUserProductLiveClasses = async (liveClass: LiveClass) => {
    // setUserProgress([]);

    const users = await LiveClassService.readClassUsersAsObject(lcId);
    const userScores = await ProgressService.getAllUserScores();
    const userProgress: UserProgress[] = [];

    for (let user in userScores) {
      if (!users[user] || !userScores[user][liveClass.lcCourseId]) continue;
      const { userName, userEmail, userUid } = users[user];

      userProgress.push({
        userFullname: userName,
        userEmail,
        userUid,
        userCourseProgress: userScores[user][liveClass.lcCourseId],
      });
    }
    console.log("user progress:", userProgress);
    setUserProgress(userProgress);
  };

  useEffect(() => {
    (async () => {
      const lcRes = (await LiveClassService.readOneLiveClass(
        lcId
      )) as LiveClass;
      setLiveClass(lcRes || ({} as LiveClass));
      await fetchUserProductLiveClasses(lcRes);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContainer headerTitle={"Class Details"}>
      <BreadcrumbsComponent url={url} />
      <Details
        liveClass={liveClass}
        onRefresh={() => fetchUserProductLiveClasses(liveClass)}
      />
      <Progress userProgress={userProgress} />
    </AppContainer>
  );
};

export default LiveClassProgress;
