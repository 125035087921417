import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#d70f64",
    },
    secondary: {
      main: grey[900],
    },
    info: {
      main: grey[50],
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          marginTop: "1rem",
          padding: "1rem",
          borderRadius: "1rem",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        secondary: {
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        },
      },
    },
  },
});
