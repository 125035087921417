import {
  equalTo,
  get,
  orderByChild,
  query,
  ref,
  remove,
  set,
  update,
} from "firebase/database";
import { LiveClass, Session, SessionUser, User } from "../interfaces";
import { db } from "./firebaseApp";
import GroupService from "./group.service";
import LiveClassService from "./liveclass.service";

const SessionService = {
  async createSession(data: Session) {
    // create new session
    const { sessionId, lcId, groupId } = data;
    const sessionRef = ref(
      db,
      `/sepContent/liveClass/lcSessionList/english/${sessionId}`
    );
    const snapshot = await get(sessionRef);
    if (snapshot.exists()) throw new Error("Session title is used");
    await set(sessionRef, data);

    // add all relevant group users by the same lcId into session
    const groupUsers = await GroupService.readAllGroupUser(groupId);
    const userRef = ref(db, `/userProductList/session/`);

    const updates: {
      [path: string]: SessionUser;
    } = {};
    groupUsers.forEach((user) => {
      const { userUid, userEmail, userName } = user;
      updates[`${user.userUid}/${sessionId}`] = {
        lcId,
        sessionId,
        userEmail,
        userName,
        userLcGroupId: groupId,
        userAttended: false,
        userId: userUid,
      };
    });
    await update(userRef, updates);

    // update live class's start and end timestamp
    const allSessions = await this.readAllSessions(lcId);
    // find the earliest start date from all sessions
    const earliestStartDate = Math.min(
      ...allSessions.map((session) => session.sessionStartDateTs)
    );
    // find the latest end date from all sessions
    const latestEndDate = Math.max(
      ...allSessions.map((session) => session.sessionEndDateTs)
    );

    // update the live class's timestamp
    const liveClass = await LiveClassService.readOneLiveClass(lcId);
    const updatedLiveClass: LiveClass = {
      ...(liveClass as LiveClass),
      lcStartDateTs: earliestStartDate,
      lcEndDateTs: latestEndDate,
    };
    await LiveClassService.updateLiveClass(updatedLiveClass, groupId);
  },

  async createDraftSession(data: Session) {
    const sessionRef = ref(
      db,
      `/sepContent/liveClass/lcDraft/lcSessionDraftList/${data.sessionId}`
    );
    const snapshot = await get(sessionRef);
    if (snapshot.exists()) throw new Error("Session title is used");
    return await set(sessionRef, data);
  },

  async addUserToSession(user: User, data: Session) {
    const userRef = ref(
      db,
      `/userProductList/session/${user.userUid}/${data.sessionId}`
    );
    const snapshot = await get(userRef);
    if (snapshot.exists())
      throw new Error("User was previously added into this session.");

    const { sessionId, lcId, groupId } = data;
    const { userUid, email, full_name } = user;
    const massagedData: SessionUser = {
      lcId,
      sessionId,
      userLcGroupId: groupId,
      userAttended: false,
      userId: userUid,
      userEmail: email,
      userName: full_name,
    };
    return await set(userRef, massagedData);
  },

  async addManyUserToSession(users: SessionUser[]) {
    const sessionRef = ref(db, `/userProductList/session/`);
    const updates = users.reduce(
      (acc, curr) => ({ ...acc, [`${curr.userId}/${curr.sessionId}`]: curr }),
      {}
    );
    return await update(sessionRef, updates);
  },

  async markAttendance(uid: string, sessionId: string, checked: boolean) {
    const attendanceRef = ref(
      db,
      `/userProductList/session/${uid}/${sessionId}`
    );
    const snapshot = await get(attendanceRef);
    if (!snapshot.exists()) throw new Error("ref provided does not exist");

    return await update(attendanceRef, { userAttended: checked });
  },

  async readAllSessions(lcId: string): Promise<Session[]> {
    const sessionRef = query(
      ref(db, "/sepContent/liveClass/lcSessionList/english/"),
      orderByChild("lcId"),
      equalTo(lcId)
    );
    const snapshot = await get(sessionRef);
    const sessions: Session[] = [];
    snapshot.forEach((session) => {
      sessions.push(session.val());
    });
    return sessions;
  },

  async readAllSessionCount(lcId: string): Promise<number> {
    const sessionRef = query(
      ref(db, "/sepContent/liveClass/lcSessionList/english/"),
      orderByChild("lcId"),
      equalTo(lcId)
    );
    const snapshot = await get(sessionRef);
    return snapshot.size;
  },

  async readAllClassSession(lcId: string) {
    const sessionRef = query(
      ref(db, "/sepContent/liveClass/lcSessionList/english/"),
      orderByChild("lcId"),
      equalTo(lcId)
    );
    const snapshot = await get(sessionRef);
    const sessions: Session[] = [];

    snapshot.forEach((snap) => {
      sessions.push(snap.val());
    });
    return sessions;
  },

  async readOneSession(sessionId: string): Promise<Session> {
    const sessionRef = ref(
      db,
      `/sepContent/liveClass/lcSessionList/english/${sessionId}`
    );
    const snapshot = await get(sessionRef);
    return await snapshot.val();
  },

  async readAllDraftSessions(): Promise<Session[]> {
    const sessionRef = ref(
      db,
      "/sepContent/liveClass/lcDraft/lcSessionDraftList"
    );
    const snapshot = await get(sessionRef);
    const sessions: Session[] = [];
    snapshot.forEach((session) => {
      sessions.push(session.val());
    });
    return sessions;
  },

  async readAllSessionUsers(sessionId: string): Promise<SessionUser[]> {
    const usersRef = ref(db, `/userProductList/session`);
    const snapshot = await get(usersRef);
    const users: SessionUser[] = [];
    snapshot.forEach((user) => {
      if (user.child(sessionId).exists()) {
        users.push(user.val()[sessionId]);
      }
    });
    return users;
  },

  async updateSession(data: Session) {
    const { sessionId, lcId, groupId } = data;
    const sessionRef = ref(
      db,
      `/sepContent/liveClass/lcSessionList/english/${sessionId}`
    );
    await update(sessionRef, data);

    const allSessions = await this.readAllSessions(lcId);
    // find the earliest start date from all sessions
    const earliestStartDate = Math.min(
      ...allSessions.map((session) => session.sessionStartDateTs)
    );
    // find the latest end date from all sessions
    const latestEndDate = Math.max(
      ...allSessions.map((session) => session.sessionEndDateTs)
    );

    // update the live class's timestamp
    const liveClass = await LiveClassService.readOneLiveClass(lcId);
    const updatedLiveClass: LiveClass = {
      ...(liveClass as LiveClass),
      lcStartDateTs: earliestStartDate,
      lcEndDateTs: latestEndDate,
    };
    return await LiveClassService.updateLiveClass(updatedLiveClass, groupId);
  },

  async deleteUserSession(uid: string, sessionId: string) {
    const userSessionRef = ref(
      db,
      `/userProductList/session/${uid}/${sessionId}`
    );
    return await remove(userSessionRef);
  },

  async deleteSession(sessionId: string) {
    // remove the session
    const sessionRef = ref(
      db,
      `/sepContent/liveClass/lcSessionList/english/${sessionId}`
    );
    await remove(sessionRef);

    // remove users associated with
    const sessionUsers = await this.readAllSessionUsers(sessionId);
    const updates: any = sessionUsers.reduce(
      (acc, curr) => ({ ...acc, [`${curr.userId}/${curr.sessionId}`]: null }),
      {}
    );
    const userSessionRef = ref(db, "/userProductList/session/");
    return await update(userSessionRef, updates);
  },
};

export default SessionService;
