import React, { useEffect, useState } from "react";
import {
  Avatar,
  IconButton,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { RemoveRedEyeOutlined } from "@mui/icons-material";

import { BoxColumn } from "../../../utils/styledComponents";
import UserService from "../../../services/user.service";
import { User } from "../../../interfaces";
import { Link, useHistory } from "react-router-dom";
import GeneralTableComponent from "../../../components/GeneralTableComponent";
import { makeAvatarName } from "../../../utils/makeAvatarName";
import Moment from "react-moment";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";

const validationSchema = yup.object({
  searchTerm: yup
    .string()
    .email("Must contain @ and domain")
    .required("Email is required"),
});

const UserTable = () => {
  const STRINGS = {
    INVALID_USER: "Invalid User",
    NOT_FOUND: "User Not Found",
    VIEW_USER: "View User",
  };
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);

  const searchFormik = useFormik({
    initialValues: {
      searchTerm: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const email = values.searchTerm.trim();
      try {
        const user = await searchUser(email);
        if (!user.userUid)
          return enqueueSnackbar(STRINGS.INVALID_USER, { variant: "error" });
        history.push(`user/${user.userUid}`);
      } catch (e: any) {
        return enqueueSnackbar(e.message, { variant: "error" });
      }
    },
  });

  const avatarDisplay = (user: User) => {
    return (
      <Avatar src={user.userPhoto || ""}>
        {makeAvatarName(user.full_name || user.userName)}
      </Avatar>
    );
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchUser = async (email: string) => {
    const results = await UserService.readAllUserBySearching(email);
    if (results.length <= 0) throw new Error(STRINGS.NOT_FOUND);

    return results[0];
  };

  const call = async () => {
    setLoading(true);
    const results = await UserService.readAllUser();
    setUsers(results);
    setLoading(false);
  };

  useEffect(() => {
    call();
  }, []);

  return (
    <GeneralTableComponent
      onRefresh={call}
      loading={loading}
      searchFormik={searchFormik}
      searchLabel={STRINGS.VIEW_USER}
    >
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="center">Last Login</TableCell>
          <TableCell>View</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(rowsPerPage > 0
          ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : users
        ).map((user) => (
          <TableRow key={user.userUid}>
            <TableCell sx={{ display: "flex", alignItems: "center" }}>
              {avatarDisplay(user)}
              <BoxColumn sx={{ pl: "1rem" }}>
                {user.full_name || user.userName}
                <Typography variant="caption">{user.email}</Typography>
              </BoxColumn>
            </TableCell>
            <TableCell align="center">
              {/* todo: change to dynamic online checker */}
              {user.lastLogin ? (
                <Moment format="DD MMM, yyyy HH:mm:ss A">
                  {user.lastLogin}
                </Moment>
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>
              <Link to={`/user/${user.userUid}`}>
                <IconButton>
                  <RemoveRedEyeOutlined />
                </IconButton>
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[50, 100, 250]}
            count={users.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableRow>
      </TableFooter>
    </GeneralTableComponent>
  );
};

export default UserTable;
