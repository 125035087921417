import {
  Button,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import GeneralTableComponent from "../../../../components/GeneralTableComponent";
import { Cell, Session } from "../../../../interfaces";
import SessionService from "../../../../services/session.service";

export default function SessionsTable() {
  const STRINGS = {
    VIEW_SESSION: "View Session",
  };

  const cells: Cell[] = [
    { name: "Title", align: "left" },
    { name: "Actions", align: "right" },
  ];

  const { url } = useRouteMatch();
  const { lcId }: any = useParams();
  const [sessions, setSessions] = useState<Session[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const call = async () => {
    setLoading(true);
    const sessions = await SessionService.readAllSessions(lcId);
    setSessions(sessions);
    setLoading(false);
  };

  useEffect(() => {
    call();
  }, []);

  return (
    <GeneralTableComponent onRefresh={call} loading={loading}>
      <TableHead>
        <TableRow>
          {cells.map((cell) => (
            <TableCell align={cell.align} key={cell.name}>
              {cell.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {(rowsPerPage > 0
          ? sessions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : sessions
        ).map(({ sessionTitle, sessionId }) => (
          <TableRow key={sessionId}>
            <TableCell align="left">{sessionTitle}</TableCell>
            <TableCell align="right">
              <Button
                sx={{ textDecoration: "none" }}
                color="primary"
                variant="outlined"
                component={Link}
                to={`${url}/${sessionId}`}
              >
                {STRINGS.VIEW_SESSION}
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            count={sessions.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableRow>
      </TableFooter>
    </GeneralTableComponent>
  );
}
