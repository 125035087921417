import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "../../pages/Login";
import Home from "../../pages/Home";
import UserPage from "../../pages/User";
import UserDetails from "../../pages/ClassDetails/UserDetails";
import LiveClass from "../../pages/LiveClass";
import CoursePage from "../../pages/Course";
import CertificatePage from "../../pages/Certificate";
import GroupPage from "../../pages/Group";
import NotFoundPage from "../../pages/NotFound";

const Routes = () => {
  return (
    <Switch>
      {/* admin */}
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute path="/user" component={UserPage} />
      <PrivateRoute path="/live-class" component={LiveClass} />
      <PrivateRoute path="/course" component={CoursePage} />
      <PrivateRoute
        path="/class-details/:classId/user-details/:UserId"
        component={UserDetails}
      />
      <PrivateRoute path="/group" component={GroupPage} />
      <PrivateRoute path="/certificate" component={CertificatePage} />

      {/* authentication */}
      <PublicRoute path="/login" component={Login} />

      {/*<ComingSoon/>*/}

      {/* 404 Not Found */}
      <Route path="/not-found" component={NotFoundPage} />
      <Redirect path="*" to="/not-found" />
    </Switch>
  );
};

export default Routes;
