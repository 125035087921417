import React, { FC, useEffect, useState } from "react";
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import { RemoveRedEyeOutlined, Delete, Send } from "@mui/icons-material";
import { BoxColumn } from "../../../../utils/styledComponents";
import { Link } from "react-router-dom";
import { GroupUser } from "../../../../interfaces";
import UserService from "../../../../services/user.service";
import LiveClassService from "../../../../services/liveclass.service";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import GeneralTableComponent from "../../../../components/GeneralTableComponent";
import { useSnackbar } from "notistack";

const TableComponent: FC<Props> = ({ url, lcId, refresh }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userId, setUserId] = useState("");
  const openMenu = Boolean(anchorEl);
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [users, setUsers] = useState<GroupUser[]>([]);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setUserId("");
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSendPasswordResetLink = async (uid: string) => {
    const selectedUser = users.find((user) => user.userUid === uid);
    if (!selectedUser) return;
    console.log("selected email", selectedUser.userEmail);
    try {
      await UserService.createResetPasswordLink(selectedUser.userEmail);
      enqueueSnackbar(
        `Successfully Sent Password Reset Link to ${selectedUser.userEmail}`,
        {
          variant: "success",
        }
      );
    } catch (e) {
      console.error(e);
      enqueueSnackbar(`Failed to Send Password Reset Link`, {
        variant: "error",
      });
    }
  };

  const call = async () => {
    setLoading(true);
    const results = await LiveClassService.readClassUsers(lcId);
    setUsers(results);
    setLoading(false);
  };

  // const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
  //   const { checked, name } = e.target;
  //   if (name === "all") {
  //     if (checked) {
  //       const selected = users.map((user) => user.userUid);
  //       return setSelected(selected);
  //     }
  //     setSelected([]);
  //   } else {
  //     if (checked) {
  //       return setSelected([...selected, name]);
  //     }
  //     const index = selected.indexOf(name);
  //     if (index === -1) return;
  //     setSelected([...selected.slice(0, index), ...selected.slice(index + 1)]);
  //   }
  // };

  // const handleDeleteUser = async () => {
  //   if (!selected.length) {
  //     enqueueSnackbar(STRINGS.NO_USER_SELECTED, { variant: "error" });
  //   }
  //   await LiveClassService.deleteManyUserLiveClass(selected, lcId);
  //   enqueueSnackbar(STRINGS.SUCCESS_REMOVED_USERS, { variant: "success" });
  //   call();
  // };

  useEffect(() => {
    call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <GeneralTableComponent
      onRefresh={call}
      loading={loading}
      // onRemove={handleDeleteUser}
      // disableRemove={!selected.length}
    >
      <Table>
        <TableHead>
          <TableRow>
            {/* <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                checked={selected.length === users.length}
                name="all"
                onChange={handleCheckbox}
              />
            </TableCell> */}
            <TableCell>Name</TableCell>
            <TableCell align="center">View</TableCell>
            {/* <TableCell>Actions</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : users
          ).map((row) => (
            <TableRow key={row.userUid}>
              {/* <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={selected.includes(row.userUid)}
                  name={row.userUid}
                  onChange={handleCheckbox}
                />
              </TableCell> */}
              <TableCell sx={{ display: "flex", alignItems: "center" }}>
                <BoxColumn sx={{ pl: "1rem" }}>
                  {row.userName}
                  <Typography variant="caption">{row.userEmail}</Typography>
                  <Typography variant="caption" color="text.secondary">
                    {row.userUid}
                  </Typography>
                </BoxColumn>
              </TableCell>
              <TableCell align="center">
                <Link to={`/user/${row.userUid}`}>
                  <IconButton>
                    <RemoveRedEyeOutlined />
                  </IconButton>
                </Link>
              </TableCell>
              {/* <TableCell>
                <IconButton
                  onClick={(e) => {
                    handleOpenMenu(e, row.userUid);
                  }}
                >
                  <MoreHoriz />
                </IconButton>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[50, 100, 250]}
              count={users.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={() => handleSendPasswordResetLink(userId)}>
          <ListItemIcon>
            <Send />
          </ListItemIcon>
          <ListItemText>Send Reset Password Link</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleOpenDialog}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText>Remove From Class</ListItemText>
        </MenuItem>
      </Menu>
      <GeneralDialogComponent
        open={openDialog}
        onClose={handleCloseDialog}
        title={"Remove User from Class"}
        actions={
          <>
            <Button
              variant="contained"
              color="info"
              onClick={handleCloseDialog}
              sx={{ mr: "1rem" }}
            >
              Cancel
            </Button>
            <Button variant="contained" color="error">
              Delete
            </Button>
          </>
        }
      >
        <Typography sx={{ pt: "1.5rem", pb: "1.5rem" }}>
          Are you sure? You can't undo this action afterwards.
        </Typography>
      </GeneralDialogComponent>
    </GeneralTableComponent>
  );
};

export default TableComponent;

interface Props {
  url: string;
  lcId: string;
  refresh: boolean;
}
