import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import BreadcrumbsComponent from '../../../components/Breadcrumbs'
import AppContainer from '../../../containers/AppContainer'
import Progress from './components/Progress'

const StudentDetailsPage = () => {
  const {url} = useRouteMatch()

  return <AppContainer headerTitle={'Student Details'}>
    <BreadcrumbsComponent url={url}/>
    <Progress/>
  </AppContainer>
}

export default StudentDetailsPage
