import { MakeTextField } from "../interfaces";

export const checkTextFieldError = <T>(
  textField: MakeTextField<T>,
  form: T
) => {
  const { name, required, pattern, value } = textField;
  if (typeof value === "string") {
    return !String(form[name]).trim()
      ? required
      : !new RegExp(pattern)?.test(value);
  }
  return !form[name] && required;
};
