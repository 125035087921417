import {
  AddUser,
  User,
  UserDetail,
  UserMeta,
  UserObjectList,
} from "../interfaces";
import { db, functions } from "./firebaseApp";
import {
  equalTo,
  get,
  limitToFirst,
  orderByChild,
  query,
  ref,
  set,
  update,
} from "firebase/database";
import { httpsCallable } from "firebase/functions";

const UserService = {
  async createUser(data: AddUser) {
    const callable = httpsCallable(functions, "createUser");
    const res = await callable(data);
    return await res.data;
  },

  async createResetPasswordLink(email: string) {
    const callable = httpsCallable(functions, "resetPassword");
    const res = await callable({ email });
    return await res.data;
  },

  async readAllUser(): Promise<User[]> {
    const userRef = ref(db, "/users");
    const snapshot = await get(userRef);
    const users: User[] = [];
    snapshot.forEach((snap) => {
      users.push({ uid: snap.key, ...snap.val() });
    });
    return users;
  },

  async readAllUserBySearching(email: string): Promise<User[]> {
    const userRef = query(
      ref(db, "/users"),
      orderByChild("email"),
      equalTo(email)
    );
    const snapshot = await get(userRef);
    const users: User[] = [];
    snapshot.forEach((snap) => {
      users.push({ uid: snap.key, ...snap.val() });
    });
    return users;
  },

  async readAllUserAsObject(): Promise<UserObjectList> {
    const userRef = ref(db, "/users");
    const snapshot = await get(userRef);
    return await snapshot.val();
  },

  async readOneUser(uid: string): Promise<User> {
    const userRef = ref(db, `/users/${uid}`);
    const snapshot = await get(userRef);
    return snapshot.val();
  },

  async readOneUserMeta(uid: string): Promise<UserMeta> {
    const userRef = ref(db, `/userProfileMeta/${uid}`);
    const snapshot = await get(userRef);
    return snapshot.val();
  },

  async updateUser(data: UserDetail) {
    const {
      classId,
      full_name,
      email,
      emailVerified,
      lastLogin,
      mobile,
      userPhoto,
      userName,
      userUid,
      userIC,
      userCountry,
      userLang,
      userGender,
      userAge,
      userEthnicity,
      userCity,
      userAddress,
      userPhoneSecondary,
      userEmailSecondary,
      userType,
      userCreatedAt,
      userUpdatedAt,
      userUpdatedBy,
      userBadge,
      userStatus,
      userBio,
      userAgreement,
      userEmailConsent,
    } = data;

    const userData: User = {
      classId,
      full_name,
      email,
      emailVerified,
      lastLogin,
      mobile,
      userPhoto,
      userName,
      userUid,
    };

    const userMetaData: UserMeta = {
      userIC,
      userCountry,
      userLang,
      userGender,
      userAge,
      userEthnicity,
      userCity,
      userAddress,
      userPhoneSecondary,
      userEmailSecondary,
      userType,
      userCreatedAt,
      userUpdatedAt,
      userUpdatedBy,
      userBadge,
      userStatus,
      userBio,
      userAgreement,
      userEmailConsent,
    };

    const userRef = ref(db, `/users/${data.userUid}`);
    const userMetaRef = ref(db, `/userProfileMeta/${data.userUid}`);

    await update(userRef, userData);
    return await update(userMetaRef, userMetaData);
  },

  async uploadUserProfile(formData: FormData) {
    const callable = httpsCallable(functions, "uploadUserPhoto");
    return await callable(formData);
  },

  async inactivateUser(uid: string) {
    const userRef = ref(db, `/userProfileMeta/${uid}`);
    return await update(userRef, { userStatus: "Inactive" });
  },

  async activateUser(uid: string) {
    const userRef = ref(db, `/userProfileMeta/${uid}`);
    return await update(userRef, { userStatus: "Active" });
  },

  async welcomeUser(email: string, full_name: string) {
    const callable = httpsCallable(functions, "welcomeUser");
    return await callable({ email, full_name });
  },
  async getUserPwdResetCount(uid: any) {
    const userMetaRef = ref(db, `/userProfileMeta/${uid}/userPwdResetCount`);
    const snapshot = await get(userMetaRef);
    return snapshot.val();
  },
  async setUserPwdResetCount(uid: any) {
    const userMetaRef = ref(db, `/userProfileMeta/${uid}/userPwdResetCount`);
    const snapshot = await get(userMetaRef);
    const count = snapshot.val();
    const newCount = count + 1;
    return await set(userMetaRef, newCount);
  },
  async getWelcomeEmailCount(uid: any) {
    const userMetaRef = ref(
      db,
      `/userProfileMeta/${uid}/userWelcomeEmailCount`
    );
    const snapshot = await get(userMetaRef);
    return snapshot.val();
  },
  async setWelcomeEmailCount(uid: any) {
    const userMetaRef = ref(
      db,
      `/userProfileMeta/${uid}/userWelcomeEmailCount`
    );
    const snapshot = await get(userMetaRef);
    const count = snapshot.val();
    const newCount = count + 1;
    return await set(userMetaRef, newCount);
  },
};

export default UserService;
