import {User} from "firebase/auth";
import {createContext, useContext, useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../services/firebaseApp";
import {AuthenticationService} from "../../services/authentication.service";
import BackdropComponent from "../../components/BackDropComponent";

// default value
interface DefaultValue {
  user: User | null | undefined;
  loading: boolean;
  role: string;
  setRole: Function;
  roleTitle: string
  setRoleTitle: Function
}

const defaultValue: DefaultValue = {
  user: null,
  loading: false,
  role: "",
  setRole: () => {
  },
  roleTitle: "",
  setRoleTitle: () => {
  }
};

// context
export const AuthContext = createContext(defaultValue);

// provider
export const AuthProvider = ({children}: any) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentLoading, setCurrentLoading] = useState(true);
  const [role, setRole] = useState("");
  const [roleTitle, setRoleTitle] = useState("");

  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    const call = async () => {
      // Check for user status
      if (user) {
        await user.getIdTokenResult(true).then((idTokenResult) => {
          if (!!idTokenResult.claims.role) {
            setRole(idTokenResult.claims.role as string);
            if (idTokenResult.claims.roleTitle) {
              setRoleTitle(idTokenResult.claims.roleTitle as string);
            }
            setCurrentUser(user);
            setCurrentLoading(false);
          } else {

            setCurrentUser(null);
            setCurrentLoading(false);
            setRoleTitle("")
            setRole("")
            AuthenticationService.signOut();
          }

        });

      }


    }


    call()
  }, [loading]);

  if (loading) {
    return <BackdropComponent/>
  }
  return (
    <AuthContext.Provider value={{user, loading: currentLoading, role, setRole, roleTitle, setRoleTitle}}>
      {children}
    </AuthContext.Provider>
  );
};

// hooks
export const useAuth: () => DefaultValue = () => useContext(AuthContext);
