import React from 'react'
import {Drawer} from '@mui/material'
import Contents from './Contents';

export default function SideBarComponent(props: Props) {

  const {open, handleDrawerToggle} = props;

  return (
    <Drawer
      open={open}
      onClose={handleDrawerToggle}
      
    >
      <Contents/>
    </Drawer>
  )
}

interface Props {
  open: boolean,
  handleDrawerToggle: () => void
}

