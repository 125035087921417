import {
  Autocomplete,
  Grid,
  TextField,
  Typography,
  Divider,
  ListItem,
  ListItemText,
  Avatar,
} from "@mui/material";
import React, {
  ChangeEvent,
  FC,
  HTMLInputTypeAttribute,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import moment from "moment";
import { REGEX } from "../../../../constants/regex.constant";
import {
  CreateClassForm,
  CreateClassFormKeys,
  LiveClass,
  LiveClassForm,
} from "../../../../interfaces";
import LiveClassService from "../../../../services/liveclass.service";
import { useAuth } from "../../../../utils/providers/AuthProvider";
import { useHistory } from "react-router-dom";
import {
  AutoCompleteOption,
  MakeTextField,
  MakeTextFields,
} from "../../../../interfaces/TextField.interface";
import VerticalDivider from "../../../../components/VerticalDivider";
import EditClassButtons from "./EditClassButtons";
import { CreateClassFormTemplate } from "../../CreateLiveClass/components/EmptyFormTemplate";
import { make } from "../../../../utils/makeAutoCompleteOption";
import GroupService from "../../../../services/group.service";
import { convertTitleToId } from "../../../../utils/convertString";
import { Image } from "@mui/icons-material";
import { checkTextFieldError } from "../../../../utils/checkTextField";
import { useSnackbar } from "notistack";

const EditClassForm: FC<Props> = ({ lcId }) => {
  let history = useHistory();
  const [form, setForm] = useState<LiveClassForm>(
    CreateClassFormTemplate as LiveClassForm
  );
  const { enqueueSnackbar } = useSnackbar();

  const [errors, setErrors] = useState<FormErrors>({} as FormErrors);
  const [options, setOptions] =
    useState<AutoCompleteOptions>(autoCompleteOptions);
  const { user } = useAuth();
  const [currentGroup, setCurrentGroup] = useState("");

  const makeTextField = (
    label: string,
    type: HTMLInputTypeAttribute | "textarea" | "autocomplete",
    key: CreateClassFormKeys,
    pattern: RegExp,
    required: boolean = false,
    disabled: boolean = false
  ): MakeTextField<CreateClassForm> => ({
    label,
    type: type === "autocomplete" ? "autocomplete" : type,
    key,
    pattern,
    disabled,
    value: form[key],
    error: errors[key],
    helperText: errors[key]
      ? required && !form[key]
        ? "This field is required"
        : `Invalid ${label}`
      : "",
    required,
    name: key,
    multiline: type === "textarea",
    rows: 4,
  });

  const textFields: MakeTextFields<CreateClassForm> = {
    lcTitle: makeTextField(
      "Live Class Title",
      "text",
      "lcTitle",
      REGEX.ANY,
      true
    ),
    lcUserGroup: makeTextField(
      "User Group",
      "autocomplete",
      "lcUserGroup",
      REGEX.ANY,
      true
    ),
    lpId: makeTextField(
      "Parent Live Class",
      "autocomplete",
      "lpId",
      REGEX.ANY,
      true
    ),
    lcCourseId: makeTextField(
      "Linked Course",
      "autocomplete",
      "lcCourseId",
      REGEX.ANY
    ),
    lcType: makeTextField(
      "Live Class Type",
      "autocomplete",
      "lcType",
      REGEX.ANY
    ),
    lcCourseType: makeTextField(
      "Live Class Course Type",
      "autocomplete",
      "lcCourseType",
      REGEX.ANY
    ),
    lcDescHtml: makeTextField(
      "Live Class HTML",
      "autocomplete",
      "lcDescHtml",
      REGEX.ANY,
      true
    ),
    lcCourseLength: makeTextField(
      "Live Class Course Length",
      "text",
      "lcCourseLength",
      REGEX.ANY
    ),
    lcImage: makeTextField("Live Class Image", "text", "lcImage", REGEX.ANY),
    teacherName: makeTextField(
      "Teacher Name",
      "autocomplete",
      "teacherName",
      REGEX.ANY,
      true
    ),
    teacherEmail: makeTextField(
      "Teacher Email",
      "text",
      "teacherEmail",
      REGEX.ANY,
      true,
      true
    ),
    lcDataA: makeTextField("Extra Data A", "textarea", "lcDataA", REGEX.ANY),
    lcDataB: makeTextField("Extra Data B", "textarea", "lcDataB", REGEX.ANY),
    lcDesc: makeTextField(
      "Live Class Description",
      "textarea",
      "lcDesc",
      REGEX.ANY,
      false,
      true
    ),
    lcAge: makeTextField("Age Group", "autocomplete", "lcAge", REGEX.ANY),
    lcCategory: makeTextField(
      "Live Class Category",
      "autocomplete",
      "lcCategory",
      REGEX.ANY
    ),
    lcLevel: makeTextField(
      "Live Class Level",
      "autocomplete",
      "lcLevel",
      REGEX.ANY
    ),
    lcMeetingMsg: makeTextField(
      "Meeting Message",
      "textarea",
      "lcMeetingMsg",
      REGEX.ANY
    ),
    lcPrerequisite: makeTextField(
      "Prerequisite",
      "textarea",
      "lcPrerequisite",
      REGEX.ANY
    ),
    lcSessionsDates: makeTextField(
      "Sessions Dates",
      "text",
      "lcSessionsDates",
      REGEX.ANY
    ),
    lcTagId: makeTextField("Tag ID", "text", "lcTagId", REGEX.ANY),
    lcTargetLang: makeTextField(
      "Target Language",
      "text",
      "lcTargetLang",
      REGEX.ANY
    ),
    lcProgramId: makeTextField(
      "Live Class Program",
      "autocomplete",
      "lcProgramId",
      REGEX.ANY
    ),
    lcSupportId: makeTextField(
      "Live Class Support",
      "autocomplete",
      "lcSupportId",
      REGEX.ANY
    ),
    targetGroup: makeTextField(
      "Target Group",
      "text",
      "targetGroup",
      REGEX.ANY
    ),
    lcStartDateTs: makeTextField(
      "Live Class Start Date",
      "datetime-local",
      "lcStartDateTs",
      REGEX.ANY,
      false,
      true
    ),
    lcEndDateTs: makeTextField(
      "Live Class End Date",
      "datetime-local",
      "lcEndDateTs",
      REGEX.ANY,
      false,
      true
    ),
    createdAt: makeTextField(
      "Live Class Created At",
      "datetime-local",
      "createdAt",
      REGEX.ANY,
      false,
      true
    ),
    updatedAt: makeTextField(
      "Live Class Updated At",
      "datetime-local",
      "updatedAt",
      REGEX.ANY,
      false,
      true
    ),
    updatedBy: makeTextField(
      "Live Class Updated By",
      "text",
      "updatedBy",
      REGEX.ANY,
      false,
      true
    ),
    totalSlots: makeTextField(
      "Total Slots",
      "number",
      "totalSlots",
      REGEX.NUMBER
    ),
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { required, value } = e.target;
    const name = e.target.name as CreateClassFormKeys;
    setForm({ ...form, [name]: value });

    const pattern = textFields[name].pattern;
    const error: boolean = !value
      ? required
      : !new RegExp(pattern)?.test(value);
    setErrors({ ...errors, [name]: error });
  };

  const handleAutoCompleteChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: AutoCompleteOption | null,
    name: CreateClassFormKeys
  ) => {
    e.preventDefault();

    setForm({ ...form, [name]: newValue?.value });
    if (name === "lpId") {
      setForm({
        ...form,
        [name]: newValue?.value,
        lcDesc: newValue?.subtitle as string,
      });
    }
    if (name === "teacherName") {
      setForm({
        ...form,
        [name]: newValue?.value,
        teacherEmail: newValue?.subtitle as string,
      });
    }
  };

  const handleUpdate = async () => {
    // validate form
    const hasError = Object.values(textFields).some((textField) => {
      const { name, label } = textField;
      const error: boolean = checkTextFieldError(textField, form);
      if (error) {
        setErrors({ ...errors, [name]: error });
        enqueueSnackbar(`Invalid ${label}`, { variant: "error" });
      }
      return error;
    });

    if (hasError) return;

    const massagedForm: LiveClass = {
      ...form,
      lcStartDateTs: (moment(form.lcStartDateTs).valueOf() as number) || "",
      lcEndDateTs: (moment(form.lcEndDateTs).valueOf() as number) || "",
      programTitle: form.lcProgramId ? form.lcProgramId : "",
      supportTitle: form.lcSupportId ? form.lcSupportId : "",
      teacherId: convertTitleToId(form.teacherName),
      createdAt: moment(form.createdAt).valueOf(),
      updatedAt: moment().valueOf(),
      updatedBy: user?.email || "",
      lcStatus: "Save",
      lcIndex: 99999999,
      lcIndexAsc: 0,
    };

    try {
      await LiveClassService.updateLiveClass(massagedForm, currentGroup);
      history.push(`/live-class/${lcId}`);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const handleDelete = async () => {
    try {
      await LiveClassService.deleteLiveClass(lcId);
      history.push(`/live-class`);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    (async () => {
      const lcRes = (await LiveClassService.readOneLiveClass(
        lcId
      )) as LiveClassForm;
      setForm({
        ...lcRes,
        lcStartDateTs: moment(lcRes.lcStartDateTs).format("YYYY-MM-DDTHH:mm"),
        lcEndDateTs: moment(lcRes.lcEndDateTs).format("YYYY-MM-DDTHH:mm"),
        createdAt: moment(lcRes.createdAt).format("YYYY-MM-DDTHH:mm"),
        updatedAt: moment(lcRes.createdAt).format("YYYY-MM-DDTHH:mm"),
      });
      setCurrentGroup(lcRes.lcUserGroup);

      const courseIds = await LiveClassService.readCourses();
      const classHtmls = await LiveClassService.readLiveClassesHtml();
      const parentLiveClasses = await LiveClassService.readParentLiveClasses();
      const userGroups = await GroupService.readAllGroup();
      const teachers = await LiveClassService.readTeachers();

      setOptions({
        ...options,
        lcCourseId: courseIds.map((id) => ({
          title: id.courseTitle,
          value: id.courseId,
          subtitle: id.courseDesc,
        })),
        lcDescHtml: classHtmls.map((html) => ({
          title: html.lcTitle,
          value: html.lcDescHtml,
          subtitle: html.lcDescHtml,
        })),
        lpId: parentLiveClasses.map((lp) => ({
          title: lp.lpTitle,
          value: lp.lpId,
          subtitle: lp.lpDesc,
        })),
        lcUserGroup: userGroups.map((group) => ({
          title: group.groupTitle,
          value: group.groupId,
        })),
        teacherName: teachers.map((teacher) => ({
          title: teacher.teacherName,
          value: teacher.teacherName,
          subtitle: teacher.teacherEmail,
        })),
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} lg={8}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ mt: "3rem" }}>
            Main Details
          </Typography>
        </Grid>
        {/* <Grid container alignItems="center" my="1rem">
          <ClassImage form={form} setForm={setForm} />
        </Grid> */}
        <Grid item xs={12}>
          <Avatar
            variant="rounded"
            src={form.lcImage || ""}
            sx={{ m: "2rem 1rem", width: 150, height: 150 }}
          >
            <Image fontSize="large" />
          </Avatar>
        </Grid>
        <Grid container spacing={3}>
          {Object.values(textFields)
            .slice(0, 9)
            .map((textField) => (
              <Grid item xs={12} md={6} key={textField.key}>
                {textField.type === "autocomplete" ? (
                  <Autocomplete
                    disablePortal
                    fullWidth
                    disabled={textField.disabled}
                    options={options[textField.key as AutoCompleteOptionKeys]}
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option) => (
                      <ListItem {...props} title={option.title}>
                        <ListItemText
                          primary={option.title}
                          secondary={option.subtitle || ""}
                        />
                      </ListItem>
                    )}
                    isOptionEqualToValue={(option) =>
                      option.value === form[textField.key]
                    }
                    value={options[
                      textField.key as AutoCompleteOptionKeys
                    ]?.find((option) => option.value === form[textField.key])}
                    onChange={(e, newValue) =>
                      handleAutoCompleteChange(e, newValue, textField.name)
                    }
                    // random key needs to be used in order to rerender the autocomplete field after async event
                    key={`autocomplete-${textField.key}-${
                      Math.random() * 1000
                    }`}
                    renderInput={(params) => (
                      <TextField margin="normal" {...params} {...textField} />
                    )}
                  />
                ) : (
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    {...textField}
                  />
                )}
              </Grid>
            ))}
        </Grid>

        <Divider sx={{ my: "2rem" }} />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">Additional Details</Typography>
          </Grid>
          {Object.values(textFields)
            .slice(9, 30)
            .map((textField) => (
              <Grid item xs={12} md={6} key={textField.key}>
                {textField.type === "autocomplete" ? (
                  <Autocomplete
                    disablePortal
                    fullWidth
                    disabled={textField.disabled}
                    options={options[textField.key as AutoCompleteOptionKeys]}
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option) => (
                      <ListItem {...props} title={option.title}>
                        <ListItemText
                          primary={option.title}
                          secondary={option.subtitle || ""}
                        />
                      </ListItem>
                    )}
                    isOptionEqualToValue={(option) =>
                      option.value === form[textField.key]
                    }
                    value={options[
                      textField.key as AutoCompleteOptionKeys
                    ]?.find((option) => option.value === form[textField.key])}
                    onChange={(e, newValue) =>
                      handleAutoCompleteChange(e, newValue, textField.name)
                    }
                    // random key needs to be used in order to rerender the autocomplete field after async event
                    key={`autocomplete-${textField.key}-${
                      Math.random() * 1000
                    }`}
                    renderInput={(params) => (
                      <TextField margin="normal" {...params} {...textField} />
                    )}
                  />
                ) : (
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    {...textField}
                  />
                )}
              </Grid>
            ))}
        </Grid>
      </Grid>
      <VerticalDivider />
      <EditClassButtons
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
        liveClass={form as LiveClass}
      />
    </Grid>
  );
};

export default EditClassForm;

interface Props {
  lcId: string;
}

interface AutoCompleteOptions {
  lcStatus: AutoCompleteOption[];
  lcCourseId: AutoCompleteOption[];
  lcDescHtml: AutoCompleteOption[];
  lcAge: AutoCompleteOption[];
  lcType: AutoCompleteOption[];
  lcCourseType: AutoCompleteOption[];
  lpId: AutoCompleteOption[];
  lcCategory: AutoCompleteOption[];
  lcLevel: AutoCompleteOption[];
  lcUserGroup: AutoCompleteOption[];
  lcProgramId: AutoCompleteOption[];
  lcSupportId: AutoCompleteOption[];
  teacherName: AutoCompleteOption[];
}

type AutoCompleteOptionKeys = keyof AutoCompleteOptions;

const autoCompleteOptions: AutoCompleteOptions = {
  lcStatus: [make("Save"), make("Publish")],
  lcCourseId: [],
  lcDescHtml: [],
  lcAge: [
    make("Below 15"),
    make("15 to 18"),
    make("Above 18"),
    make("Above 30"),
  ],
  lcType: [make("Long", "long"), make("Short", "short")],
  lcCourseType: [make("Long", "long"), make("Short", "short")],
  lpId: [],
  lcCategory: [make("General")],
  lcLevel: [make("Beginner")],
  lcUserGroup: [],
  lcProgramId: [make("Food Panda", "foodPanda")],
  lcSupportId: [make("Food Panda", "foodPanda")],
  teacherName: [],
};

type FormErrors = {
  [F in CreateClassFormKeys]: boolean;
};
