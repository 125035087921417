import React from "react";
import { useRouteMatch } from "react-router-dom";
import BreadcrumbsComponent from "../../../components/Breadcrumbs";
import AppContainer from "../../../containers/AppContainer";
import ClassCreateForm from "./components/CreateClassForm";

const CreateLiveClass = () => {
  const { url } = useRouteMatch();

  return (
    <AppContainer headerTitle={"Create Live Class"}>
      <BreadcrumbsComponent url={url} />
      <ClassCreateForm />
    </AppContainer>
  );
};

export default CreateLiveClass;
