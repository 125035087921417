import { Grid } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import BreadcrumbsComponent from "../../../components/Breadcrumbs";
import VerticalDivider from "../../../components/VerticalDivider";
import AppContainer from "../../../containers/AppContainer";
import { Group } from "../../../interfaces";
import GroupService from "../../../services/group.service";
import GroupActionButtons from "./component/GroupActionButtons";
import GroupDetailsForm from "./component/GroupDetailsForm";

const GroupDetailsPage = () => {
  const STRINGS = {
    TITLE: "Group Details",
  };

  const { url } = useRouteMatch();
  const { groupId }: any = useParams();
  const history = useHistory();

  const [group, setGroup] = useState<Group>();
  const [updating, setUpdating] = useState(false);

  const handleUpdate = () => {
    setUpdating(!updating);
  };

  const call = async () => {
    const group = await GroupService.readOneGroup(groupId);
    if (!group) return history.push("/not-found");

    const data: Group = {
      ...group,
      createdAt: moment(group.createdAt).format("YYYY-MM-DDThh:mm"),
      updatedAt: moment(group.updatedAt).format("YYYY-MM-DDThh:mm"),
    };
    setGroup(data);
  };

  useEffect(() => {
    call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContainer headerTitle={STRINGS.TITLE}>
      <BreadcrumbsComponent url={url} />
      {group ? (
        <Grid container>
          <GroupDetailsForm group={group} updating={updating} />
          <VerticalDivider />
          <GroupActionButtons
            group={group}
            updating={updating}
            handleUpdate={handleUpdate}
          />
        </Grid>
      ) : null}
    </AppContainer>
  );
};

export default GroupDetailsPage;
