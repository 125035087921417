import { Delete } from "@mui/icons-material";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import { GroupUser, User } from "../../../../interfaces";
import GroupService from "../../../../services/group.service";

export default function ViewUserGroupDialog(props: Props) {
  const STRINGS = {
    GROUPS: "Groups",
    DISMISS: "Dismiss",
    USER_HAS_NO_GROUP: "User Has No Groups",
    REMOVE: "Remove ",
    REMOVE_GROUP: "Remove Group",
    CANCEL: "Cancel",
    CONFIRM: "Confirm",
    SUCCESS_REMOVE_GROUP: "Successfully Removed Group",
  };

  const { user, handleClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [groups, setGroups] = useState<GroupUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [removing, setRemoving] = useState("");

  const handleCloseRemoveGroup = () => setRemoving("");
  const handleConfirmRemove = async () => {
    try {
      await GroupService.deleteUserFromGroup(removing, user.userUid);
      handleCloseRemoveGroup();
      enqueueSnackbar(STRINGS.SUCCESS_REMOVE_GROUP, {
        variant: "success",
      });
      call();
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  const call = async () => {
    setLoading(true);
    const groups = await GroupService.readAllGroupOfUser(user.userUid);
    setGroups(groups);
    setLoading(false);
  };

  useEffect(() => {
    call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GeneralDialogComponent
      open
      onClose={handleClose}
      title={`${user.full_name || user.userName} ${STRINGS.GROUPS}`}
      actions={<Button onClick={handleClose}>{STRINGS.DISMISS}</Button>}
    >
      <List>
        {!loading ? (
          groups.length ? (
            groups.map((group) => (
              <ListItem
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => setRemoving(group.groupId)}
                  >
                    <Delete />
                  </IconButton>
                }
                key={group.groupId}
              >
                <ListItemText primary={group.groupName} />
              </ListItem>
            ))
          ) : (
            <Typography variant="body1" color="textSecondary">
              {STRINGS.USER_HAS_NO_GROUP}
            </Typography>
          )
        ) : (
          <ListItem
            secondaryAction={
              <IconButton edge="end" disabled>
                <Delete />
              </IconButton>
            }
          >
            <ListItemText
              primary={<Skeleton variant="text" height={16} width={200} />}
              secondary={<Skeleton variant="text" height={14} width={150} />}
            />
          </ListItem>
        )}
      </List>

      <GeneralDialogComponent
        open={Boolean(removing)}
        onClose={handleCloseRemoveGroup}
        title={STRINGS.REMOVE_GROUP}
        actions={
          <>
            <Button variant="outlined" onClick={handleCloseRemoveGroup}>
              {STRINGS.CANCEL}
            </Button>
            <Button variant="contained" onClick={handleConfirmRemove}>
              {STRINGS.CONFIRM}
            </Button>
          </>
        }
      >
        <Typography variant="body1">
          {STRINGS.REMOVE}
          {groups.find((group) => group.groupId === removing)?.groupName}?
        </Typography>
      </GeneralDialogComponent>
    </GeneralDialogComponent>
  );
}

interface Props {
  user: User;
  handleClose: () => void;
}
