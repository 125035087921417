import { Add, Remove, Search } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Stack,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GeneralDialogComponent from "../../../../../components/GeneralDialogComponent";
import { REGEX } from "../../../../../constants/regex.constant";
import { Session, SessionUser, User } from "../../../../../interfaces";
import SessionService from "../../../../../services/session.service";
import UserService from "../../../../../services/user.service";
import { makeAvatarName } from "../../../../../utils/makeAvatarName";

export default function AddUserToSessionBox(props: Props) {
  const STRINGS = {
    ADD_USER_TO_SESSION: "Add User To Session",
    ADD: "Add",
    PLEASE_SELECT_USER: "Please Select an Existing User",
    SUCCESS_ADD_USER_TO_SESSION: "Successfully Added Users to Session!",
    FAILED_ADD_USER_TO_SESSION: "User might have!",
    SELECTED_USERS: "Selected Users",
    RESULTS: "Results",
    INVALID_EMAIL: "Invalid Email Format",
    NO_RESULTS: "No Results",
    USER_EXIST: "User Was Added To This Session",
    USER_SELECTED: "User Was Selected",
  };

  const { sessionUsers } = props;
  const { sessionId }: any = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [input, setInput] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [session, setSession] = useState<Session>({} as Session);
  const [disableSubmit, setDisableSubmit] = useState(true);

  // methods
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDisableSubmit(false);
    setInput(e.target.value);
  };

  const handleSearch = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!input.match(REGEX.EMAIL))
      return enqueueSnackbar(STRINGS.INVALID_EMAIL, { variant: "error" });

    const users = await UserService.readAllUserBySearching(input);
    if (!users.length)
      return enqueueSnackbar(STRINGS.NO_RESULTS, { variant: "warning" });

    // check user existence in the group
    const userExists = sessionUsers.findIndex(
      (user) => user.userId === users[0].userUid
    );
    if (userExists !== -1)
      return enqueueSnackbar(STRINGS.USER_EXIST, { variant: "warning" });

    const userSelected = selectedUsers.findIndex(
      (user) => user.userUid === users[0].userUid
    );
    if (userSelected !== -1)
      return enqueueSnackbar(STRINGS.USER_SELECTED, { variant: "warning" });

    setUsers(users);
  };

  const handleAdd = (uid: string) => {
    // find the user's index to remove from 'users'
    const userIndex = users.findIndex((user) => user.userUid === uid);
    if (userIndex === -1) return;

    // add into selected users
    setSelectedUsers([...selectedUsers, users[userIndex]]);

    // remove from results' users
    const newUsers = [
      ...users.slice(0, userIndex),
      ...users.slice(userIndex + 1),
    ];
    setUsers(newUsers);
  };

  const handleRemove = (uid: string) => {
    // find the user's index to remove from 'users'
    const userIndex = selectedUsers.findIndex((user) => user.userUid === uid);
    if (userIndex === -1) return;

    // add into selected users
    setUsers([...users, selectedUsers[userIndex]]);

    // remove from results' users
    const newUsers = [
      ...selectedUsers.slice(0, userIndex),
      ...selectedUsers.slice(userIndex + 1),
    ];
    setSelectedUsers(newUsers);
  };

  const handleSubmit = async () => {
    // if (group.slotsAvailable < users.length) {
    //   enqueueSnackbar(STRINGS.INSUFFICIENT_SLOTS, { variant: "error" });
    //   return;
    // }

    try {
      setDisableSubmit(true);
      const sessionUsers: SessionUser[] = selectedUsers.map((user) => ({
        lcId: session.lcId,
        sessionId,
        userLcGroupId: "",
        userAttended: false,
        userId: user.userUid,
        userEmail: user.email,
        userName: user.full_name || user.userName,
      }));

      await SessionService.addManyUserToSession(sessionUsers);
      enqueueSnackbar(STRINGS.ADD_USER_TO_SESSION, { variant: "success" });
      handleClose();
    } catch (error: any) {
      setDisableSubmit(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  useEffect(() => {
    (async () => {
      if (!open) return;

      const session = await SessionService.readOneSession(sessionId);
      setSession(session);

      setUsers(users);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Box width="100%" mt="1rem" display="flex" justifyContent="flex-end">
        <Button variant="contained" onClick={handleOpen} startIcon={<Add />}>
          {STRINGS.ADD_USER_TO_SESSION}
        </Button>
      </Box>
      <GeneralDialogComponent
        open={open}
        onClose={handleClose}
        title={STRINGS.ADD_USER_TO_SESSION}
        actions={
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={disableSubmit}
          >
            {STRINGS.ADD}
          </Button>
        }
      >
        <Box width="100%" display="flex" alignItems="center">
          <Box
            component="form"
            onSubmit={handleSearch}
            width="100%"
            display="flex"
            alignItems="center"
            noValidate
          >
            <TextField
              type="email"
              label="User Email"
              placeholder="Enter user email"
              fullWidth
              onChange={handleChange}
            />

            {/* seach icon */}
            <InputAdornment position="end">
              <IconButton size="small" type="submit">
                <Search fontSize="small" />
              </IconButton>
            </InputAdornment>
          </Box>
        </Box>

        <Stack>
          <ListSubheader>{STRINGS.SELECTED_USERS}</ListSubheader>
          <List dense>
            {selectedUsers.map(
              ({ userUid, userPhoto, full_name, userName, email }) => (
                <ListItem
                  key={userUid}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      onClick={() => handleRemove(userUid)}
                    >
                      <Remove />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar src={userPhoto || ""}>
                      {makeAvatarName(full_name)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={full_name || userName}
                    secondary={email}
                  />
                </ListItem>
              )
            )}
          </List>

          <Divider />

          <ListSubheader>{STRINGS.RESULTS}</ListSubheader>
          <List dense>
            {users.map(({ userUid, userPhoto, full_name, userName, email }) => (
              <ListItem
                key={userUid}
                secondaryAction={
                  <IconButton edge="end" onClick={() => handleAdd(userUid)}>
                    <Add />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar src={userPhoto || ""}>
                    {makeAvatarName(full_name)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={full_name || userName}
                  secondary={email}
                />
              </ListItem>
            ))}
          </List>
        </Stack>
      </GeneralDialogComponent>
    </>
  );
}

interface Props {
  sessionUsers: SessionUser[];
}
