import { Delete, Image } from "@mui/icons-material";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import GeneralAvatarComponent from "../../../../components/GeneralAvatarComponent";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import { User, UserProductCourse } from "../../../../interfaces";
import { CourseService } from "../../../../services/course.service";

export default function ViewUserCourseDialog(props: Props) {
  const STRINGS = {
    COURSES: "Live Courses",
    DISMISS: "Dismiss",
    USER_HAS_NO_COURSE: "User Has No Courses",
    REMOVE: "Remove ",
    REMOVE_COURSE: "Remove Class",
    CANCEL: "Cancel",
    CONFIRM: "Confirm",
    SUCCESS_REMOVE_COURSE: "Successfully Removed Course",
  };

  const { user, handleClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [courses, setCourses] = useState<UserProductCourse[]>([]);
  const [loading, setLoading] = useState(false);
  const [removing, setRemoving] = useState("");

  const handleCloseRemoveCourse = () => setRemoving("");
  const handleConfirmRemove = async () => {
    try {
      await CourseService.deleteUserCourse(user.userUid, removing);
      handleCloseRemoveCourse();
      enqueueSnackbar(STRINGS.SUCCESS_REMOVE_COURSE, {
        variant: "success",
      });
      call();
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: "success",
      });
    }
  };

  const call = async () => {
    setLoading(true);
    const courses = await CourseService.readOneUserAllProductCourse(
      user.userUid
    );
    setCourses(courses);
    setLoading(false);
  };

  useEffect(() => {
    call();
  }, []);

  return (
    <GeneralDialogComponent
      open
      onClose={handleClose}
      title={`${user.full_name || user.userName} ${STRINGS.COURSES}`}
      actions={<Button onClick={handleClose}>{STRINGS.DISMISS}</Button>}
    >
      <List>
        {!loading ? (
          courses.length ? (
            courses.map((course) => (
              <ListItem
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => setRemoving(course.courseId)}
                  >
                    <Delete />
                  </IconButton>
                }
                key={course.courseId}
              >
                <GeneralAvatarComponent src={course.courseImage}>
                  <Image />
                </GeneralAvatarComponent>
                <ListItemText
                  primary={course.courseTitle}
                  secondary={course.courseDesc}
                />
              </ListItem>
            ))
          ) : (
            <Typography variant="body1" color="textSecondary">
              {STRINGS.USER_HAS_NO_COURSE}
            </Typography>
          )
        ) : (
          <ListItem
            secondaryAction={
              <IconButton edge="end" disabled>
                <Delete />
              </IconButton>
            }
          >
            <ListItemText
              primary={<Skeleton variant="text" height={16} width={200} />}
            />
          </ListItem>
        )}
      </List>

      <GeneralDialogComponent
        open={Boolean(removing)}
        onClose={handleCloseRemoveCourse}
        title={STRINGS.REMOVE_COURSE}
        actions={
          <>
            <Button variant="outlined" onClick={handleCloseRemoveCourse}>
              {STRINGS.CANCEL}
            </Button>
            <Button variant="contained" onClick={handleConfirmRemove}>
              {STRINGS.CONFIRM}
            </Button>
          </>
        }
      >
        <Typography variant="body1">
          {STRINGS.REMOVE}
          {courses.find((course) => course.courseId === removing)?.courseTitle}?
        </Typography>
      </GeneralDialogComponent>
    </GeneralDialogComponent>
  );
}

interface Props {
  user: User;
  handleClose: () => void;
}
