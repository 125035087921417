import { Add } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import AddSessionDialog from "../../../../components/AddSessionDialog";

export default function AddGroupBox() {
  const STRINGS = {
    ADD_SESSION: "Add Session",
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box width="100%" mt="1rem" display="flex" justifyContent="flex-end">
        <Button variant="contained" onClick={handleOpen} startIcon={<Add />}>
          {STRINGS.ADD_SESSION}
        </Button>
      </Box>
      {open ? <AddSessionDialog handleClose={handleClose} /> : null}
    </>
  );
}
