import { Button, Grid, List, ListItem, ListItemText } from "@mui/material";
import React from "react";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import { LiveClass } from "../../../../interfaces";

const ViewDraftDialog = (props: Props) => {
  const { onClose, draft } = props;
  return draft ? (
    <GeneralDialogComponent
      open
      onClose={onClose}
      title={draft.lcTitle}
      actions={
        <Button variant="contained" onClick={onClose}>
          Ok
        </Button>
      }
    >
      <List>
        <Grid container>
          {Object.keys(draft).map((key) => (
            <Grid item xs={12} md={6}>
              <ListItem key={key}>
                <ListItemText
                  primary={key}
                  secondary={draft[key as keyof LiveClass] || "-"}
                />
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </List>
    </GeneralDialogComponent>
  ) : null;
};

interface Props {
  onClose: () => void;
  draft: LiveClass | null | undefined;
}

export default ViewDraftDialog;
