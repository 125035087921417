import {
  Autocomplete,
  Button,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { SyntheticEvent, useEffect, useState } from "react";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import { Group, GroupUser, User } from "../../../../interfaces";
import GroupService from "../../../../services/group.service";

export default function AddUserToGroupDialog(props: Props) {
  const STRINGS = {
    ADD_USER_TO_GROUP: "Add User To Group",
    ADD: "Add",
    MUST_ASSIGN_GROUP: "Please assign user to a group",
    SUCCESS_ADD_GROUP: "Successfully Added User To Group",
  };

  const { user, handleClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedGroup, setSelectedGroup] = useState({} as Group);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const handleAutoCompleteChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: Group | null
  ) => {
    if (!newValue) return;
    e.preventDefault();
    setDisableSubmit(false);
    setSelectedGroup(newValue);
  };

  const handleSubmit = async () => {
    if (!selectedGroup)
      return enqueueSnackbar(STRINGS.MUST_ASSIGN_GROUP, { variant: "warning" });

    try {
      setDisableSubmit(true);
      const groupUser: GroupUser = {
        userName: user.full_name,
        userEmail: user.email,
        userUid: user.userUid,
        groupId: selectedGroup.groupId,
        groupName: selectedGroup.groupTitle,
      };
      await GroupService.addUserToGroup(groupUser);
      enqueueSnackbar(STRINGS.SUCCESS_ADD_GROUP, { variant: "success" });
      handleClose();
    } catch (error: any) {
      setDisableSubmit(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  useEffect(() => {
    (async () => {
      const groupsEnrolled = await GroupService.readAllGroupOfUser(
        user.userUid
      );

      const groups = (await GroupService.readAllGroup()).filter(
        (group) =>
          groupsEnrolled.findIndex(
            (groupEnrolled) => groupEnrolled.groupId === group.groupId
          ) === -1 && group.slotsAvailable > 0
      );
      setGroups(groups);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GeneralDialogComponent
      open
      onClose={handleClose}
      title={STRINGS.ADD_USER_TO_GROUP}
      actions={
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={disableSubmit}
        >
          {STRINGS.ADD}
        </Button>
      }
    >
      {/* groups */}
      <Autocomplete
        fullWidth
        handleHomeEndKeys
        options={groups}
        getOptionLabel={(option) => option.groupTitle}
        onChange={(e, newValue) => handleAutoCompleteChange(e, newValue)}
        renderOption={(props, { groupId, groupTitle }) => (
          <ListItem key={groupId} {...props}>
            <ListItemText primary={groupTitle} secondary={groupId} />
          </ListItem>
        )}
        renderInput={(params) => (
          <TextField label="Group" margin="normal" {...params} />
        )}
      />
    </GeneralDialogComponent>
  );
}

interface Props {
  user: User;
  handleClose: () => void;
}
