import { Image } from "@mui/icons-material";
import { Avatar, AvatarProps } from "@mui/material";
import React from "react";

export default function GeneralAvatarComponent(props: AvatarProps) {
  return (
    <Avatar
      variant="square"
      sx={{ width: 45, height: 45, borderRadius: "5px", marginRight: "1rem" }}
      {...props}
    >
      <Image />
    </Avatar>
  );
}
