import React, { SyntheticEvent, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import { BoxRow } from "../../../../utils/styledComponents";
import { LiveClass, User, UserProductLiveClass } from "../../../../interfaces";
import LiveClassService from "../../../../services/liveclass.service";
import GeneralAvatarComponent from "../../../../components/GeneralAvatarComponent";
import moment from "moment";

export default function AddUserToClassDialog(props: Props) {
  const STRINGS = {
    ADD_USER_TO_LIVE_CLASS: "Add User to Live Class",
    CANCEL: "Cancel",
    ADD: "Add",
    SUCCESS_ADD_USER_TO_CLASS: "Successfully Added User to Class",
    FAILED_ADD_USER_TO_CLASS: "Failed to Add User to Class",
    PLEASE_SELECT_CLASS: "Please Select a Live Class",
  };

  const { handleClose, user } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [liveClasses, setLiveClasses] = useState<LiveClass[]>([]);
  const [selectedLiveClass, setSelectedLiveClass] = useState<LiveClass>();

  // methods
  const handleAutoCompleteChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: LiveClass | null
  ) => {
    e.preventDefault();
    if (newValue) setSelectedLiveClass(newValue);
  };
  const handleAddUserToClass = async () => {
    if (!selectedLiveClass)
      return enqueueSnackbar(STRINGS.PLEASE_SELECT_CLASS, {
        variant: "warning",
      });

    const { lcTitle, lcId, lcImage, lcStartDateTs, lcEndDateTs } =
      selectedLiveClass;
    const massagedData: UserProductLiveClass = {
      lcTitle,
      lcId,
      lcImage,
      lcStartDateTs: lcStartDateTs || 0,
      lcEndDateTs: lcEndDateTs || 0,
    };
    try {
      await LiveClassService.addUserToClass(massagedData, user);
      enqueueSnackbar(STRINGS.SUCCESS_ADD_USER_TO_CLASS, {
        variant: "success",
      });
      handleClose();
    } catch (error: any) {
      enqueueSnackbar(STRINGS.FAILED_ADD_USER_TO_CLASS, { variant: "error" });
    }
  };

  useEffect(() => {
    (async () => {
      const userLiveClasses = await LiveClassService.readUserProductLiveClasses(
        user.userUid
      );

      const liveClasses = (await LiveClassService.readLiveClasses()).filter(
        (liveClass) =>
          userLiveClasses.findIndex(
            (userLiveClass) => userLiveClass.lcId === liveClass.lcId
          ) === -1
      );
      setLiveClasses(liveClasses);
    })();
  }, [user]);

  return (
    <GeneralDialogComponent
      open
      onClose={handleClose}
      title={STRINGS.ADD_USER_TO_LIVE_CLASS}
      actions={
        <>
          <Button
            variant="contained"
            color="info"
            onClick={handleClose}
            sx={{ mr: "1rem" }}
          >
            {STRINGS.CANCEL}
          </Button>
          <Button
            variant="contained"
            onClick={handleAddUserToClass}
            disabled={!selectedLiveClass}
          >
            {STRINGS.ADD}
          </Button>
        </>
      }
    >
      <Autocomplete
        fullWidth
        options={liveClasses}
        getOptionLabel={(option) => option.lcTitle}
        onChange={(e, newValue) => handleAutoCompleteChange(e, newValue)}
        renderOption={(props, option) => (
          <Box component="li" key={option.lcId} {...props}>
            <BoxRow p="0.5rem" display="flex" alignItems="center">
              <GeneralAvatarComponent src={option.lcImage} />
              <Box>
                <Typography>
                  {option.lcTitle} (
                  {moment(option.lcStartDateTs).format("DD MMM y, HH:mm:ss A")}
                  {" - "}
                  {moment(option.lcEndDateTs).format("DD MMM y, HH:mm:ss A")})
                </Typography>
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    lineClamp: 3,
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                  }}
                  variant="caption"
                  color="textSecondary"
                >
                  {option.lcDesc}
                </Typography>
              </Box>
            </BoxRow>
          </Box>
        )}
        renderInput={(params) => (
          <TextField margin="normal" label="Live Class" {...params} />
        )}
      />
    </GeneralDialogComponent>
  );
}

interface Props {
  user: User;
  handleClose: () => void;
}
