import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { BoldText, BoxRow } from "../../../../../../utils/styledComponents";

const ProgressDetail: FC<Props> = ({ id }) => {
  // dummy data
  const info = [
    { lesson: "Salt", progress: 95 },
    { lesson: "Department", progress: 95 },
    { lesson: "A lift", progress: 34 },
    { lesson: "Desks", progress: 95 },
    { lesson: "An organisation", progress: 95 },
    { lesson: "Departments", progress: 15 },
  ];
  const details = [
    { name: "1. System Words", progress: 95, info: info },
    { name: "2. Types of Nouns & Names", progress: 95, info: info },
    { name: "3. How to use Nouns", progress: 0, info: info },
    { name: "4. Using System Word with...", progress: 0, info: info },
  ];

  const displayNA = (number: number) => {
    if (number === 0) return "N/A";
    else return `${number}%`;
  };

  return (
    <Grid container sx={{ mt: "1rem" }}>
      {details.map((sec) => (
        <Grid item xs={12} sx={{ pb: "1rem" }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <BoxRow sx={{ pl: "1rem", flexGrow: 1, alignItems: "center" }}>
                <BoldText sx={{ flexGrow: 1 }}>{sec.name}</BoldText>
                <LinearProgress
                  color="success"
                  variant="determinate"
                  sx={{ width: "20rem", mr: "3rem" }}
                  value={sec.progress}
                />
                <Typography sx={{ mr: "1rem" }}>
                  {displayNA(sec.progress)}
                </Typography>
              </BoxRow>
            </AccordionSummary>
            <AccordionDetails>
              {sec.info.map((lesson) => (
                <BoxRow
                  sx={{
                    height: "2.5rem",
                    pl: "1rem",
                    flexGrow: 1,
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ flexGrow: 1 }}>{lesson.lesson}</Typography>
                  <LinearProgress
                    color="success"
                    variant="determinate"
                    sx={{ width: "20rem", mr: "5rem" }}
                    value={lesson.progress}
                  />
                  <Typography>{`${lesson.progress}%`}</Typography>
                </BoxRow>
              ))}
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProgressDetail;

interface Props {
  id: string;
}
