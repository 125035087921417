import React, { useState } from "react";
import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { SideDetailsBox } from "../../../../utils/styledComponents";
import AddUserToGroupDialog from "./AddUserToGroupDialog";
import { Group } from "../../../../interfaces";
import ViewUserInGroup from "./ViewUserInGroup";
import AddGroupToClassDialog from "./AddGroupToClassDialog";
import ViewGroupClassDialog from "./ViewGroupClassDialog";
import AddCourseToGroupDialog from "./AddCourseToGroupDialog";
import ViewCourseInGroup from "./ViewCourseInGroup";

const GroupActionButtons = (props: Props) => {
  const STRINGS = {
    ACTION_BUTTONS: "Action Buttons",
    CANCEL: "Cancel",
    UPDATE_USER: "Update Group",
    USER: "User",
    ADD_USER_TO_GROUP: "Add User To Group",
    VIEW_USER: "View Users",
    LIVE_CLASS: "Live Class",
    ADD_GROUP_TO_LIVE_CLASS: "Add Group To Live Class",
    VIEW_LIVE_CLASS: "View Live Classes",
    COURSE: "Course",
    ADD_COURSE_TO_GROUP: "Add Course to Group",
    VIEW_COURSE: "View Courses",
  };

  const { group, updating, handleUpdate } = props;
  const [prompt, setPrompt] = useState<DialogTypes>("");

  const DialogComponent = () => {
    switch (prompt) {
      case "add user to group":
        return (
          <AddUserToGroupDialog
            group={group}
            handleClose={() => setPrompt("")}
          />
        );
      case "view users":
        return (
          <ViewUserInGroup group={group} handleClose={() => setPrompt("")} />
        );
      case "add group to live class":
        return (
          <AddGroupToClassDialog
            group={group}
            handleClose={() => setPrompt("")}
          />
        );
      case "view live class":
        return (
          <ViewGroupClassDialog
            group={group}
            handleClose={() => setPrompt("")}
          />
        );
      case "add course to group":
        return (
          <AddCourseToGroupDialog
            group={group}
            handleClose={() => setPrompt("")}
          />
        );
      case "view courses":
        return (
          <ViewCourseInGroup group={group} handleClose={() => setPrompt("")} />
        );
      default:
        return null;
    }
  };

  return (
    <Grid item xs={12} lg={3}>
      <Stack divider={<Divider />}>
        <SideDetailsBox>
          <Typography variant="h5" sx={{ my: "1rem" }}>
            {STRINGS.ACTION_BUTTONS}
          </Typography>
          <Stack spacing={3}>
            <Button
              variant="contained"
              color={updating ? "error" : "secondary"}
              fullWidth
              onClick={handleUpdate}
            >
              {updating ? STRINGS.CANCEL : STRINGS.UPDATE_USER}
            </Button>
          </Stack>
        </SideDetailsBox>

        <SideDetailsBox>
          <Typography variant="h5" sx={{ mb: "1rem" }}>
            {STRINGS.USER}
          </Typography>
          <Stack spacing={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => setPrompt("add user to group")}
              disabled={group.slotsAvailable <= 0}
            >
              {STRINGS.ADD_USER_TO_GROUP}
            </Button>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setPrompt("view users")}
            >
              {STRINGS.VIEW_USER}
            </Button>
          </Stack>
        </SideDetailsBox>

        <SideDetailsBox>
          <Typography variant="h5" sx={{ mb: "1rem" }}>
            {STRINGS.LIVE_CLASS}
          </Typography>
          <Stack spacing={3}>
            {/* <Button
              variant="contained"
              fullWidth
              onClick={() => setPrompt("add group to live class")}
            >
              {STRINGS.ADD_GROUP_TO_LIVE_CLASS}
            </Button> */}
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setPrompt("view live class")}
            >
              {STRINGS.VIEW_LIVE_CLASS}
            </Button>
          </Stack>
        </SideDetailsBox>

        <SideDetailsBox>
          <Typography variant="h5" sx={{ mb: "1rem" }}>
            {STRINGS.COURSE}
          </Typography>
          <Stack spacing={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => setPrompt("add course to group")}
            >
              {STRINGS.ADD_COURSE_TO_GROUP}
            </Button>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setPrompt("view courses")}
            >
              {STRINGS.VIEW_COURSE}
            </Button>
          </Stack>
        </SideDetailsBox>
      </Stack>

      <DialogComponent />
    </Grid>
  );
};

interface Props {
  group: Group;
  updating: boolean;
  handleUpdate: () => void;
}

type DialogTypes =
  | "add user to group"
  | "view users"
  | "add group to live class"
  | "view live class"
  | "add course to group"
  | "view courses"
  | "";

export default GroupActionButtons;
