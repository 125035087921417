import { Edit, BarChart } from "@mui/icons-material";
import { Button, IconButton, Typography, Tooltip } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import Moment from "react-moment";
import { Link, useHistory } from "react-router-dom";
import GeneralAvatarComponent from "../../../../components/GeneralAvatarComponent";
import { LiveClass } from "../../../../interfaces";
import LiveClassService from "../../../../services/liveclass.service";
import {
  BoldText,
  BoxColumn,
  BoxRow,
} from "../../../../utils/styledComponents";

const Details: FC<Props> = ({ lcId, refresh, setRefresh }) => {
  const history = useHistory();
  const [liveClass, setLiveClass] = useState<LiveClass>({} as LiveClass);

  // const [open, setOpen] = useState(false);
  // const [openAddGroup, setOpenAddGroup] = useState(false);

  // // add user
  // const handleClose = () => {
  //   call();
  //   setOpen(false);
  // };
  // const handleOpen = () => setOpen(true);

  // // add group
  // const handleOpenAddGroup = () => setOpenAddGroup(true);
  // const handleCloseAddGroup = () => setOpenAddGroup(false);

  const call = async () => {
    setRefresh(true);
    const lcRes = (await LiveClassService.readOneLiveClass(lcId)) as LiveClass;
    if (!lcRes) return history.push("/not-found");

    setLiveClass(lcRes);
    setRefresh(false);
  };

  useEffect(() => {
    call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BoxRow sx={{ pt: "1rem", pb: "1rem", flexGrow: 1 }}>
      <GeneralAvatarComponent
        src={liveClass.lcImage}
        sx={{ width: "150px", height: "150px", borderRadius: "1rem" }}
      />
      <BoxColumn sx={{ pl: "1rem", justifyContent: "center", flexGrow: 1 }}>
        <BoxRow>
          <BoldText variant="h5" mr="0.3rem">
            {liveClass.lcTitle}
          </BoldText>
          <Tooltip title="Edit">
            <IconButton
              onClick={() =>
                history.push(`/live-class/edit-class/${liveClass.lcId}`)
              }
            >
              <Edit fontSize="small" />
            </IconButton>
          </Tooltip>
        </BoxRow>
        <BoxRow sx={{ pt: "1rem" }}>
          <BoldText>Start Date:</BoldText>
          <Typography sx={{ pl: "0.5rem" }}>
            {liveClass.lcStartDateTs ? (
              <Moment format="D MMM y, HH:mm:ss A">
                {liveClass.lcStartDateTs}
              </Moment>
            ) : (
              "-"
            )}
          </Typography>
        </BoxRow>
        <BoxRow sx={{ pt: "1rem" }}>
          <BoldText>End Date:</BoldText>
          <Typography sx={{ pl: "0.5rem" }}>
            {liveClass.lcEndDateTs ? (
              <Moment format="D MMM y, HH:mm:ss A">
                {liveClass.lcEndDateTs}
              </Moment>
            ) : (
              "-"
            )}
          </Typography>
        </BoxRow>
      </BoxColumn>
      <BoxColumn sx={{ justifyContent: "flex-end" }}>
        <BoxRow>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<BarChart />}
            component={Link}
            to={`/live-class/progress/${liveClass.lcId}`}
          >
            View Progress
          </Button>
          {/* <Button
            variant="contained"
            startIcon={<Add />}
            onClick={handleOpenAddGroup}
            sx={{ ml: "1rem" }}
          >
            Add Users From Group
          </Button>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={handleOpen}
            sx={{ ml: "1rem" }}
          >
            Add User
          </Button> */}
        </BoxRow>
      </BoxColumn>
      {/* <AddUserToClassDialog
        open={open}
        liveClass={liveClass}
        handleClose={handleClose}
      />
      <AddGroupToClassDialog
        open={openAddGroup}
        handleClose={handleCloseAddGroup}
        liveClass={liveClass}
        refresh={refresh}
        setRefresh={setRefresh}
      /> */}
    </BoxRow>
  );
};

export default Details;

interface Props {
  lcId: string;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}
