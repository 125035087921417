import { Delete } from "@mui/icons-material";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import GeneralAvatarComponent from "../../../../components/GeneralAvatarComponent";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import { Group, GroupCourseRelation } from "../../../../interfaces";
import GroupService from "../../../../services/group.service";

const ViewCourseInGroup = (props: Props) => {
  const STRINGS = {
    TITLE: "View Courses",
    CANCEL: "Cancel",
    DELETED_COURSE:
      "Deleted Course, kindly refresh the page to retrieve the latest available slots",
    DELETED_COURSE_FAILED: "Failed to Delete Course",
    NO_COURSE: "No Course Added",
  };

  const { group, handleClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [courses, setCourses] = useState<GroupCourseRelation[]>([]);

  const handleRemove = (courseId: string) => {
    // find the course's index to remove from 'courses'
    const courseIndex = courses.findIndex(
      (course) => course.courseId === courseId
    );
    if (courseIndex === -1) return;

    try {
      GroupService.deleteCourseFromGroup(group.groupId, courseId);
      enqueueSnackbar(STRINGS.DELETED_COURSE, { variant: "success" });
    } catch (e) {
      enqueueSnackbar(STRINGS.DELETED_COURSE_FAILED, { variant: "error" });
    }
    // remove from results' courses
    const newCourses = [
      ...courses.slice(0, courseIndex),
      ...courses.slice(courseIndex + 1),
    ];
    setCourses(newCourses);
  };

  const call = async () => {
    const courses = await GroupService.readAllGroupCourses(group.groupId);
    setCourses(courses);
  };

  useEffect(() => {
    call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GeneralDialogComponent
      open
      onClose={handleClose}
      title={STRINGS.TITLE}
      actions={
        <Button variant="contained" fullWidth onClick={handleClose}>
          {STRINGS.CANCEL}
        </Button>
      }
    >
      <List dense>
        {courses.length > 0 ? (
          courses.map(({ courseId, courseTitle, courseImage }) => (
            <ListItem
              key={courseId}
              secondaryAction={
                <IconButton edge="end" onClick={() => handleRemove(courseId)}>
                  <Delete />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <GeneralAvatarComponent src={courseImage} alt={courseTitle} />
              </ListItemAvatar>
              <ListItemText primary={courseTitle} />
            </ListItem>
          ))
        ) : (
          <Typography variant="body1">{STRINGS.NO_COURSE}</Typography>
        )}
      </List>
    </GeneralDialogComponent>
  );
};

interface Props {
  group: Group;
  handleClose: () => void;
}

export default ViewCourseInGroup;
