import { Delete, Refresh, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  Table,
  TableContainer,
  Tooltip,
  Typography,
} from "@mui/material";
import { FormikProps } from "formik";
import React, { ReactNode } from "react";

export default function GeneralTableComponent(props: Props) {
  const STRINGS = {
    SEARCH: "Search",
    REFRESH: "Refresh",
    SEARCH_DISABLED: "Search is disabled and not implemented",
    REFRESH_DISABLED: "Refresh is disabled",
    REMOVE: "Remove",
    REMOVE_DISABLED: "Remove is disabled",
  };

  const {
    children,
    onRefresh,
    loading,
    onRemove,
    disableRemove,
    searchFormik,
    searchLabel,
  } = props;

  return (
    <Box>
      <Card>
        <TableContainer>
          <Box display="flex" justifyContent="flex-end">
            {searchFormik ? (
              <Box
                component="form"
                width="100%"
                onSubmit={searchFormik.handleSubmit}
              >
                <Paper
                  elevation={0}
                  sx={{
                    flex: 1,
                    p: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "grey.100",
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={STRINGS.SEARCH}
                    name="searchTerm"
                    startAdornment={
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    }
                    value={searchFormik.values.searchTerm}
                    onChange={searchFormik.handleChange}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={searchFormik.isSubmitting}
                    startIcon={
                      searchFormik.isSubmitting ? (
                        <CircularProgress size={12} color="secondary" />
                      ) : null
                    }
                  >
                    {searchLabel || STRINGS.SEARCH}
                  </Button>
                </Paper>
                <Typography variant="subtitle2" color="error">
                  {searchFormik.touched.searchTerm &&
                    searchFormik.errors.searchTerm}
                </Typography>
              </Box>
            ) : null}

            <Tooltip
              title={onRefresh ? STRINGS.REFRESH : STRINGS.REFRESH_DISABLED}
            >
              <IconButton
                sx={{ ml: "1rem", padding: "1rem" }}
                onClick={onRefresh}
                disabled={!onRefresh}
              >
                <Refresh />
              </IconButton>
            </Tooltip>

            {onRemove ? (
              <Tooltip title={STRINGS.REMOVE}>
                <IconButton
                  sx={{ ml: "1rem", padding: "1rem" }}
                  onClick={onRemove}
                  disabled={disableRemove}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>

          {loading ? (
            <Box
              width="100%"
              height="300px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Table>{children}</Table>
          )}
        </TableContainer>
      </Card>
    </Box>
  );
}

interface Props {
  children: ReactNode;
  onRefresh?: () => void;
  loading?: boolean;
  onRemove?: () => void;
  disableRemove?: boolean;
  searchFormik?: FormikProps<SearchProps>;
  searchLabel?: string;
}

export interface SearchProps {
  searchTerm: string;
}
