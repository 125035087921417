import React, { useState } from "react";
import {
  Avatar,
  Box,
  CircularProgress,
  CircularProgressProps,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import {
  BoldText,
  BoxColumn,
  BoxRow,
} from "../../../../../utils/styledComponents";
import { PaperStyles } from "../../../../../constants/style-constants";
import ProgressDetail from "./components/ProgressDetail";
import { Delete, Person } from "@mui/icons-material";

const Progress = () => {
  const [moduleId, setModuleId] = useState("md1");

  // dummy data
  const modules = [
    { name: "1. Nouns Introduction", progress: 95, id: "md1" },
    { name: "2. What", progress: 98, id: "md2" },
    { name: "3. Who", progress: 55, id: "md3" },
    { name: "4. What (Abstract)", progress: 12, id: "md4" },
    { name: "5. Where", progress: 0, id: "md5" },
  ];

  const student = {
    name: "Emily Sue",
    email: "emily@gmail.com",
    progress: 40,
  };

  const avatarDisplay = (name: string) => {
    return <Avatar>{name.split(" ").map((word) => word[0])}</Avatar>;
  };

  const percentClass = (number: number) => {
    if (number > 69) {
      return <BoldText color="success.light">{`${number}%`}</BoldText>;
    } else if (number > 29) {
      return <BoldText color="warning.light">{`${number}%`}</BoldText>;
    } else if (number > 0) {
      return <BoldText color="error.main">{`${number}%`}</BoldText>;
    } else {
      return <BoldText color="grey.600">N/A</BoldText>;
    }
  };

  const selectedStyles = (id: string) => {
    if (id === moduleId) {
      return {
        bgcolor: "grey.100",
      };
    } else return;
  };

  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number }
  ) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" size="5rem" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <BoldText variant="caption" color="common.black">{`${Math.round(
            props.value
          )}%`}</BoldText>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Paper sx={PaperStyles}>
        <BoxRow sx={{ alignItems: "center", flexGrow: 1 }}>
          <Avatar sx={{ height: "7rem", width: "7rem" }}>
            {avatarDisplay(student.name)}
          </Avatar>
          <BoxColumn sx={{ ml: "2rem", flexGrow: 1 }}>
            <BoldText>{student.name}</BoldText>
            <Typography variant="caption">{student.email}</Typography>
            <BoxRow sx={{ mt: "0.5rem" }}>
              <BoxRow
                sx={{
                  alignItems: "flex-end",
                  "&:hover": { cursor: "pointer" },
                }}
              >
                <Person fontSize="small" />
                <BoldText variant="caption" sx={{ ml: "0.2rem" }}>
                  View Profile
                </BoldText>
              </BoxRow>
              <BoxRow
                sx={{
                  alignItems: "flex-end",
                  color: "error.main",
                  ml: "2rem",
                  "&:hover": { cursor: "pointer" },
                }}
              >
                <Delete fontSize="small" />
                <BoldText variant="caption" sx={{ ml: "0.2rem" }}>
                  Send Reset Password Link
                </BoldText>
              </BoxRow>
              <BoxRow
                sx={{
                  alignItems: "flex-end",
                  color: "error.main",
                  ml: "2rem",
                  "&:hover": { cursor: "pointer" },
                }}
              >
                <Delete fontSize="small" />
                <BoldText variant="caption" sx={{ ml: "0.2rem" }}>
                  Remove from class
                </BoldText>
              </BoxRow>
            </BoxRow>
          </BoxColumn>
          <BoxRow sx={{ alignItems: "center", mr: "2rem" }}>
            <Typography sx={{ mr: "1rem" }}>Course Progress</Typography>
            <CircularProgressWithLabel value={student.progress} />
          </BoxRow>
        </BoxRow>
      </Paper>
      <Grid container columnSpacing={4}>
        <Grid item md={4}>
          <Paper sx={PaperStyles}>
            <BoldText sx={{ pt: "0.5rem" }}>Modules</BoldText>
            <Divider sx={{ pt: "1rem" }} />
            <List>
              {modules.map((tab) => (
                <ListItem disablePadding sx={selectedStyles(tab.id)}>
                  <ListItemButton onClick={() => setModuleId(tab.id)}>
                    <ListItemText>
                      <BoxRow sx={{ flexGrow: 1 }}>
                        <Typography sx={{ flexGrow: 1 }}>{tab.name}</Typography>
                        <Typography>{percentClass(tab.progress)}</Typography>
                      </BoxRow>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item md={8}>
          <ProgressDetail id={moduleId} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Progress;
