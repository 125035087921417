import React from "react";
import { useRouteMatch } from "react-router-dom";
import BreadcrumbsComponent from "../../../../components/Breadcrumbs";
import AppContainer from "../../../../containers/AppContainer";
import AddSessionBox from "./AddSessionBox";
import SessionsTable from "./SessionsTable";

export default function LiveClassSessionsPage() {
  const { url } = useRouteMatch();

  return (
    <AppContainer headerTitle="Class Group">
      <BreadcrumbsComponent url={url} />
      <AddSessionBox />
      <SessionsTable />
    </AppContainer>
  );
}
