import React from "react";
import AppContainer from "../../../containers/AppContainer";
import { useRouteMatch, useParams } from "react-router-dom";
import BreadcrumbsComponent from "../../../components/Breadcrumbs";
import EditClassForm from "./components/EditClassForm";

const EditLiveClass = () => {
  const { url } = useRouteMatch();
  const { lcId }: any = useParams();

  return (
    <AppContainer headerTitle={"Class Details"}>
      <BreadcrumbsComponent url={url} />
      <EditClassForm lcId={lcId} />
    </AppContainer>
  );
};

export default EditLiveClass;
