import { RemoveRedEyeOutlined } from "@mui/icons-material";
import {
  IconButton,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import BreadcrumbsComponent from "../../../components/Breadcrumbs";
import GeneralTableComponent from "../../../components/GeneralTableComponent";
import AppContainer from "../../../containers/AppContainer";
import { Cell, Group } from "../../../interfaces";
import GroupService from "../../../services/group.service";
import { makeCell } from "../../../utils/makeCell";
import AddGroup from "./AddGroup";

const tableCells: Cell[] = [
  makeCell("Group", "left"),
  makeCell("Total Slots", "center"),
  makeCell("Slots Available", "center"),
  makeCell("View", "center"),
];

const GroupList = () => {
  const STRINGS = {
    title: "Group",
  };

  const { url } = useRouteMatch();

  const [groups, setGroups] = useState<Group[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);

  const call = async () => {
    setLoading(true);
    const res = await GroupService.readAllGroup();
    setGroups(res);
    setLoading(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    call();
  }, []);

  return (
    <AppContainer headerTitle={STRINGS.title}>
      <BreadcrumbsComponent url={url} />
      <AddGroup onRefresh={call} />
      <GeneralTableComponent onRefresh={call} loading={loading}>
        <TableHead>
          <TableRow>
            {tableCells.map(({ name, align }) => (
              <TableCell align={align} key={name}>
                {name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? groups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : groups
          ).map(({ groupId, groupTitle, totalSlots, slotsAvailable }) => (
            <TableRow key={groupId}>
              {/* title */}
              <TableCell>
                <Typography variant="body1">{groupTitle}</Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {groupId}
                </Typography>
              </TableCell>

              {/* slots */}
              <TableCell align="center">{totalSlots}</TableCell>
              <TableCell align="center">{slotsAvailable}</TableCell>

              <TableCell align="center">
                <Link to={`/group/${groupId}`}>
                  <IconButton>
                    <RemoveRedEyeOutlined />
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              count={groups.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </GeneralTableComponent>
    </AppContainer>
  );
};

export default GroupList;
