import { LiveClassForm } from "../../../../interfaces";

export const CreateClassFormTemplate: LiveClassForm = {
  // Main Details
  lcTitle: "",
  lcId: "",
  lcImage: "",
  lcType: "",
  lcCourseType: "",
  lcStartDateTs: "",
  lcEndDateTs: "",
  lcCourseLength: "",
  lcCourseId: "",
  lcDescHtml: "",
  lpId: "",

  // Additional Details
  lcDataA: "",
  lcDataB: "",
  lcDesc: "",
  lcAge: "",
  lcCategory: "",
  lcLevel: "",
  lcMeetingMsg: "",
  lcPrerequisite: "",
  lcSessionsDates: "",
  lcTagId: "",
  lcTargetLang: "",
  lcUserGroup: "",
  lcProgramId: "",
  lcSupportId: "",
  targetGroup: "",
  teacherEmail: "",
  teacherName: "",
  totalSlots: 0,
  createdAt: undefined,
  updatedAt: "",
  updatedBy: "",

}
