import {
  equalTo,
  get,
  orderByChild,
  push,
  query,
  ref,
  remove,
  runTransaction,
  set,
  update,
} from "firebase/database";
import {
  Group,
  GroupCourseRelation,
  GroupUser,
  LiveClass,
  UserProductLiveClass,
} from "../interfaces";
import { db } from "./firebaseApp";
import LiveClassService from "./liveclass.service";

const GroupService = {
  async createGroup(group: Group) {
    const groupRef = ref(db, `/sepContent/group/${group.groupId}`);
    const snapshot = await get(groupRef);
    if (snapshot.exists()) {
      throw new Error("Group ID has been used");
    }
    await set(groupRef, group);
  },

  async addUserToGroup(user: GroupUser) {
    const groupRelationRef = ref(db, "/userGroupRelation");
    const newGroupRef = push(groupRelationRef);
    await set(newGroupRef, user);

    const groupUsers = await this.readAllGroupUser(user.groupId);
    const groupRef = ref(db, `/sepContent/group/${user.groupId}`);
    await runTransaction(groupRef, (group: Group) => {
      // guard
      if (!group || !group.slotsAvailable) {
        return group;
      }

      group.slotsAvailable = group.totalSlots - groupUsers.length;
      return group;
    });
  },

  async addManyUserToGroup(users: GroupUser[]) {
    const groupRelationRef = ref(db, "/userGroupRelation");
    const updateData = users.reduce(
      (acc, curr) => ({ ...acc, [push(groupRelationRef).key as string]: curr }),
      {}
    );
    await update(groupRelationRef, updateData);

    const groupUsers = await this.readAllGroupUser(users[0].groupId);
    const groupRef = ref(db, `/sepContent/group/${users[0].groupId}`);
    await runTransaction(groupRef, (group: Group) => {
      // guard
      if (
        !group ||
        !group.slotsAvailable ||
        group.totalSlots < groupUsers.length
      ) {
        return group;
      }

      group.slotsAvailable = group.totalSlots - groupUsers.length;
      return group;
    });
  },

  async addGroupToLiveClass(groupId: string, liveClass: LiveClass) {
    const { lcId, lcTitle, lcImage, lcStartDateTs, lcEndDateTs } = liveClass;
    const groupUsers = await this.readAllGroupUser(groupId);

    // user's individual product list
    const userProductList: {
      [path: string]: UserProductLiveClass;
    } = {};
    // live class list of users
    const userProductRelation: {
      [path: string]: GroupUser;
    } = {};
    const userProductListRef = ref(db, "/userProductList/liveClass/");
    const userProductRelationRef = ref(db, "/userProductRelation/liveClass");

    groupUsers.forEach((user) => {
      // add into users' individual product list
      userProductList[`${user.userUid}/${lcId}`] = {
        lcId,
        lcTitle,
        lcImage,
        lcStartDateTs,
        lcEndDateTs,
      };
      // add user under live class
      userProductRelation[`${lcId}/${user.userUid}`] = user;
    });

    await update(userProductListRef, userProductList);
    await update(userProductRelationRef, userProductRelation);

    const updatedLiveClass = { ...liveClass };
    updatedLiveClass.lcUserGroup = groupId;
    await LiveClassService.updateLiveClass(liveClass, groupId);
  },

  async createGroupRelation(
    groupId: string,
    course: GroupCourseRelation
  ): Promise<void> {
    const groupCourseRef = ref(
      db,
      `/groupCourseRelation/${groupId}/${course.courseId}`
    );
    return await set(groupCourseRef, course);
  },

  async readAllGroup(): Promise<Group[]> {
    const groupRef = query(
      ref(db, "/sepContent/group"),
      orderByChild("createdAt")
    );
    const snapshot = await get(groupRef);
    const groups: Group[] = [];
    snapshot.forEach((group) => {
      groups.push(group.val());
    });
    return groups.reverse();
  },

  async readOneGroup(groupId: string): Promise<Group> {
    const groupRef = ref(db, `/sepContent/group/${groupId}`);
    const snapshot = await get(groupRef);
    const group: Group = await snapshot.val();
    return group;
  },

  async readAllGroupUser(groupId: string): Promise<GroupUser[]> {
    const groupRef = ref(db, "/userGroupRelation");
    const groupQuery = query(
      groupRef,
      orderByChild("groupId"),
      equalTo(groupId)
    );
    const snapshot = await get(groupQuery);
    const groupUsers: GroupUser[] = [];
    snapshot.forEach((user) => {
      groupUsers.push(user.val());
    });
    return groupUsers;
  },

  async readAllGroupOfUser(uid: string): Promise<GroupUser[]> {
    const groupRef = ref(db, "/userGroupRelation");
    const groupQuery = query(groupRef, orderByChild("userUid"), equalTo(uid));
    const snapshot = await get(groupQuery);
    const groupUsers: GroupUser[] = [];
    snapshot.forEach((user) => {
      groupUsers.push(user.val());
    });
    return groupUsers;
  },

  async readAllGroupCourses(groupId: string): Promise<GroupCourseRelation[]> {
    const groupCourseRef = ref(db, `/groupCourseRelation/${groupId}`);
    const snapshot = await get(groupCourseRef);
    const groupCourseRelations: GroupCourseRelation[] = [];
    snapshot.forEach((relation) => {
      groupCourseRelations.push(relation.val());
    });
    return groupCourseRelations;
  },

  async readOneGroupCourse(
    groupId: string,
    courseId: string
  ): Promise<GroupCourseRelation> {
    const groupCourseRef = ref(
      db,
      `/groupCourseRelation/${groupId}/${courseId}`
    );
    const snapshot = await get(groupCourseRef);
    return snapshot.val();
  },

  async updateGroup(newGroup: Group) {
    const groupRef = ref(db, `/sepContent/group/${newGroup.groupId}`);
    const snapshot = await get(groupRef);
    if (!snapshot.exists()) {
      throw new Error("Group does not exists");
    }

    const oldGroup: Group = snapshot.val();
    const difference = oldGroup.totalSlots - oldGroup.slotsAvailable;
    const group: Group = {
      ...newGroup,
      slotsAvailable: newGroup.totalSlots - difference,
    };

    return await update(groupRef, group);
  },

  async deleteUserFromGroup(groupId: string, uid: string) {
    const groupRelationRef = ref(db, `/userGroupRelation`);
    const groupQuery = query(
      groupRelationRef,
      orderByChild("userUid"),
      equalTo(uid)
    );
    const snapshot = await get(groupQuery);

    // find group from /userGroupRelation
    let groupRelationToDelete = "";
    snapshot.forEach((snap) => {
      const groupUser: GroupUser = snap.val();
      if (groupUser.groupId === groupId && snap.key) {
        groupRelationToDelete = snap.key;
      }
    });
    if (!groupRelationToDelete) {
      throw new Error("Group Not Found");
    }

    // delete it
    const groupRelationToDeleteRef = ref(
      db,
      `/userGroupRelation/${groupRelationToDelete}`
    );
    await remove(groupRelationToDeleteRef);

    // add slot
    const groupUsers = await this.readAllGroupUser(groupId);
    const groupRef = ref(db, `/sepContent/group/${groupId}`);
    await runTransaction(groupRef, (group: Group) => {
      // guard
      if (!group || group.totalSlots < groupUsers.length) {
        return group;
      }

      group.slotsAvailable = group.totalSlots - groupUsers.length;
      return group;
    });
  },

  async deleteCourseFromGroup(
    groupId: string,
    courseId: string
  ): Promise<void> {
    const groupCourseRef = ref(
      db,
      `/groupCourseRelation/${groupId}/${courseId}`
    );
    return await remove(groupCourseRef);
  },
};

export default GroupService;
