import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import { Add, RemoveRedEyeOutlined } from "@mui/icons-material";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { BoxRow } from "../../../utils/styledComponents";
import { Cell, LiveClass } from "../../../interfaces";
import LiveClassService from "../../../services/liveclass.service";
import GeneralTableComponent from "../../../components/GeneralTableComponent";
import GeneralAvatarComponent from "../../../components/GeneralAvatarComponent";
import moment from "moment";

export default function LiveClassTable() {
  const STRINGS = {
    TITLE: "Live Classes",
    ADD_CLASS: "Add Class",
  };

  let history = useHistory();
  const { url, path } = useRouteMatch();
  const [liveClasses, setLiveClasses] = useState<LiveClass[]>([]);
  const [loading, setLoading] = useState(false);

  const cells: Cell[] = [
    { name: "Class", align: "left" },
    { name: "View Session", align: "center" },
  ];

  const call = async () => {
    setLoading(true);
    const liveClasses = await LiveClassService.readLiveClasses();
    setLiveClasses(liveClasses);
    setLoading(false);
  };

  useEffect(() => {
    call();
  }, []);

  return (
    <Box sx={{ mt: "1rem" }}>
      <BoxRow sx={{ justifyContent: "flex-end" }}>
        {/* 
        <Typography variant={"h5"} gutterBottom>
          {STRINGS.TITLE}
        </Typography>
        */}
        <Button
          variant="contained"
          onClick={() => history.push(`${url}/create-class`)}
          startIcon={<Add />}
        >
          {STRINGS.ADD_CLASS}
        </Button>
      </BoxRow>

      <GeneralTableComponent onRefresh={call} loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              {cells.map((cell) => (
                <TableCell align={cell.align} key={cell.name}>
                  {cell.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {liveClasses.map((row) => (
              <TableRow key={row.lcId}>
                <TableCell>
                  <Box display="flex">
                    <GeneralAvatarComponent src={row.lcImage} />
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Typography
                        variant="subtitle2"
                        component={Link}
                        to={`${url}/${row.lcId}`}
                        sx={{ textDecoration: "none" }}
                        color="primary.main"
                      >
                        {row.lcTitle} (
                        {moment(row.lcStartDateTs).format(
                          "DD MMM y, HH:mm:ss A"
                        )}
                        {" - "}
                        {moment(row.lcEndDateTs).format("DD MMM y, HH:mm:ss A")}
                        )
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {row.lcDesc}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {row.lcId}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Link to={`${path}/${row.lcId}/session`}>
                    <IconButton>
                      <RemoveRedEyeOutlined />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </GeneralTableComponent>
    </Box>
  );
}
