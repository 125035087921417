import {Box, Button, ListItem, ListItemText, TableBody, TableCell, TableHead, TableRow,} from "@mui/material";
import React, {useEffect, useState} from "react";
import GeneralAvatarComponent from "../../../components/GeneralAvatarComponent";
import GeneralTableComponent from "../../../components/GeneralTableComponent";
import {Cell, Course} from "../../../interfaces";
import {CourseService} from "../../../services/course.service";
import {makeCell} from "../../../utils/makeCell";
import {useHistory, useRouteMatch} from "react-router-dom";

export default function CourseTable() {
  const STRINGS = {
    COURSES: "Courses",
  };
  let history = useHistory();
  const {url, path} = useRouteMatch();
  const cells: Cell[] = [makeCell(STRINGS.COURSES, "left")];
  const [courses, setCourses] = useState<Course[]>([]);
  const [loading, setLoading] = useState(false);

  const call = async () => {
    setLoading(true);
    const courses = await CourseService.readAllCourse();
    setCourses(courses);
    setLoading(false);
  };

  useEffect(() => {
    call();
  }, []);

  return (
    <Box sx={{mt: "1rem"}}>
      <GeneralTableComponent onRefresh={call} loading={loading}>
        <TableHead>
          <TableRow>
            {cells.map(({name, align}) => (
              <TableCell align={align} key={name}>
                {name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {courses.map(({courseId, courseImage, courseTitle, courseDesc}) => (
            <TableRow key={courseId}>
              <TableCell align="left">
                <ListItem>
                  <GeneralAvatarComponent src={courseImage}/>
                  <ListItemText primary={courseTitle} secondary={courseDesc}/>
                </ListItem>
                <ListItem>
                  <Button variant="text" size={"small"} disabled>View</Button>
                  <Button variant="text" size={"small"}
                          onClick={() => history.push(`${url}/course-progress/${courseId}`)}>
                    User Progress</Button>

                </ListItem>
              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </GeneralTableComponent>
    </Box>
  );
}
