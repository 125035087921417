import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../layouts/Router/PrivateRoute";
import LiveClassSessionsPage from "./components";
import SessionDetails from "./SessionDetails";

export default function Session() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path} component={LiveClassSessionsPage} />
      <PrivateRoute path={`${path}/:sessionId`} component={SessionDetails} />
    </Switch>
  );
}
