import { Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import UserService from "../../../../services/user.service";
import { User } from "../../../../interfaces";

export default function SendWelcomeEmailDialog(props: Props) {
  const STRINGS = {
    SEND_WELCOME_EMAIL: "Send Welcome User Email",
    ARE_YOU_SURE: "Are you sure? You can't undo this action afterwards.",
    CANCEL: "cancel",
    SEND: "SEND",
    SUCCESS_SEND_WELCOME_USER: "Succesfully Sent Welcome User Email",
    FAILED_SEND_WELCOME_USER: "Failed to Send Welcome User Email",
  };

  const { user, handleClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  // methods
  async function reloadPage() {
    if (window) {
      await window.location.reload();
    }
  }

  // SEND WELCOME
  const handleSendWelcomeEmail = async () => {
    setLoading(true);

    const res: any = await UserService.welcomeUser(
      user.email,
      user.full_name || user.userName
    );

    if (res) {
      enqueueSnackbar(STRINGS.SUCCESS_SEND_WELCOME_USER, {
        variant: "success",
      });
      await UserService.setWelcomeEmailCount(user.userUid);
      handleClose();
      await reloadPage();
    } else {
      enqueueSnackbar(STRINGS.FAILED_SEND_WELCOME_USER, {
        variant: "error",
      });
      setLoading(false);
    }
  };

  return (
    <GeneralDialogComponent
      open
      onClose={handleClose}
      title={STRINGS.SEND_WELCOME_EMAIL}
      actions={
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="info"
            onClick={handleClose}
            sx={{ mr: "1rem" }}
            disabled={loading}
          >
            {STRINGS.CANCEL}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleSendWelcomeEmail}
            disabled={loading}
          >
            {STRINGS.SEND}
          </Button>
        </Stack>
      }
    >
      <Typography sx={{ pt: "1.5rem", pb: "1.5rem" }}>
        {STRINGS.ARE_YOU_SURE}
      </Typography>
    </GeneralDialogComponent>
  );
}

interface Props {
  user: User;
  handleClose: () => void;
  setShowBackDrop: Function;
}
