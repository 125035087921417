import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
} from "@mui/material";
import React, { ReactNode } from "react";

const GeneralDialogComponent = (props: Props) => {
  const { open, onClose, children, title, actions } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      closeAfterTransition
      PaperProps={{
        style: { borderRadius: "1rem", width: "100%", maxWidth: "500px" },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions sx={{ padding: "1rem 1.5rem" }}>{actions}</DialogActions>
    </Dialog>
  );
};

interface Props {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title: string;
  actions: ReactNode;
}

export default GeneralDialogComponent;
