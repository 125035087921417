import { styled, Typography, Box } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export const BoldText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));

export const BoxRow = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
}));

export const BoxColumn = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

// export const DialogBox = styled(Box)(({ theme }) => ({
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   backgroundColor: theme.palette.background.paper,
//   padding: "1rem",
//   borderRadius: "1rem",
//   width: "100%",
//   maxWidth: "500px",
//   maxHeight: "80%",
//   overflow: "scroll",
// }));

export const DialogBox = styled(Box)(({ theme }) => ({
  borderRadius: "1rem",
  padding: "1rem",
}));

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.root}`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.success.light,
  },
}));

export const SideDetailsBox = styled(Box)(({ theme }) => ({
  margin: "3rem 1rem 2rem 2rem",
}));
