import React from "react";
import { useRouteMatch } from "react-router-dom";
import BreadcrumbsComponent from "../../components/Breadcrumbs";
import AppContainer from "../../containers/AppContainer";
import CertificateList from "./components/CertificateList";

const CertificatePage = () => {
  const { url } = useRouteMatch();
  return (
    <AppContainer headerTitle="Certificates">
      <BreadcrumbsComponent url={url} />
      <CertificateList />
    </AppContainer>
  );
};

export default CertificatePage;
