import { Add } from "@mui/icons-material";
import {
  Avatar,
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, useState } from "react";
import { MakeTextField, UserDetail } from "../../../../interfaces";
import { makeAvatarName } from "../../../../utils/makeAvatarName";
import axios from "axios";
import { useHistory } from "react-router-dom";
import UserService from "../../../../services/user.service";

const UserProfilePhoto = (props: Props) => {
  const STRINGS = {
    IMAGE_UPLOADED: "Successfully uploaded image!",
    FAILED_IMAGE_UPLOAD: "Failed to upload image!",
    PLEASE_USE_JPG: "Please use .jpg or .jpeg for now",
  };

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { form, userPhotoTextField, updating } = props;
  const [uploading, setUploading] = useState(false);

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e.target.files?.length) return;

    setUploading(true);
    const file = e.target.files[0];

    try {
      const formData = new FormData();
      formData.append("image", file, `/${form.userUid}.jpg`);

      const headers = {
        "content-type": "multipart/form-data",
        directory: "user-photo",
      };

      const url = process.env.REACT_APP_FIREBASE_FUNCTION_URL || "";
      if (!url) throw new Error("Missing Function URL");

      const res = await axios.post(url, formData, { headers });

      if (res) {
        enqueueSnackbar(STRINGS.IMAGE_UPLOADED, { variant: "success" });
        console.log("res:", res);
        setUploading(false);
        await UserService.updateUser({ ...form, userPhoto: res.data["url"] });
        history.push("/user");
      }
    } catch (error) {
      enqueueSnackbar(STRINGS.FAILED_IMAGE_UPLOAD, { variant: "error" });
      console.error(error);
      setUploading(false);
    }
  };

  return (
    <Grid item xs={12}>
      <label htmlFor="icon-button-file">
        {updating ? (
          <input
            accept="image/*"
            id="icon-button-file"
            type="file"
            style={{ display: "none" }}
            onChange={handleUpload}
          />
        ) : null}
        <IconButton
          aria-label="upload picture"
          component="span"
          sx={{ position: "relative" }}
          disabled={!updating}
        >
          <Avatar src={form.userPhoto || ""} sx={{ width: 100, height: 100 }}>
            {uploading ? <CircularProgress /> : makeAvatarName(form.full_name)}
          </Avatar>
          {updating ? (
            <Fab
              size="small"
              component="span"
              aria-label="upload picture"
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
              }}
            >
              <Add />
            </Fab>
          ) : null}
        </IconButton>
      </label>
      <Typography variant="caption" color="error">
        {userPhotoTextField.error ? userPhotoTextField.helperText : ""}
      </Typography>
    </Grid>
  );
};

interface Props {
  form: UserDetail;
  setForm: (user: UserDetail) => void;
  userPhotoTextField: MakeTextField<UserDetail>;
  updating: boolean;
}

export default UserProfilePhoto;
