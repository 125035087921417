import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import BreadcrumbsComponent from "../../../components/Breadcrumbs";
import AppContainer from "../../../containers/AppContainer";
import AddUserBox from "./AddUserBox";
import AddUserDialog from "./AddUserDialog";
import UserTable from "./UserTable";

const UserPage = () => {
  const STRINGS = {
    TITLE: "Users",
    ADD_USER: "Add User",
    SUBMIT: "Submit",
    SUCCESS_ADD_USER: "Successfully added User!",
    ERROR_ADD_USER: "Failed to add User!",
  };

  const { url } = useRouteMatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <AppContainer headerTitle={STRINGS.TITLE}>
      <BreadcrumbsComponent url={url} />
      <AddUserBox handleOpen={handleOpen} STRINGS={STRINGS} />
      <AddUserDialog open={open} handleClose={handleClose} STRINGS={STRINGS} />
      <UserTable />
    </AppContainer>
  );
};

export default UserPage;
