import { Button, Typography } from "@mui/material";
import React from "react";
import { useSnackbar } from "notistack";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import { useHistory } from "react-router-dom";
import UserService from "../../../../services/user.service";
import { User } from "../../../../interfaces";

export default function ActivateUserDialog(props: Props) {
  const STRINGS = {
    ACTIVATE_USER: "Activate User",
    ARE_YOU_SURE: "Are you sure? You can't undo this action afterwards.",
    CANCEL: "cancel",
    ACTIVATE: "Activate",
    SUCCESS_ACTIVATE_USER: "Successfully Actived user!",
  };

  const { user, handleClose } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  // methods
  const handleActive = async () => {
    await UserService.activateUser(user.userUid);
    enqueueSnackbar(STRINGS.SUCCESS_ACTIVATE_USER, { variant: "success" });
    handleClose();
    history.push("/user");
  };

  return (
    <GeneralDialogComponent
      open
      onClose={handleClose}
      title={STRINGS.ACTIVATE_USER}
      actions={
        <>
          <Button
            variant="contained"
            color="info"
            onClick={handleClose}
            sx={{ mr: "1rem" }}
          >
            {STRINGS.CANCEL}
          </Button>
          <Button variant="contained" color="error" onClick={handleActive}>
            {STRINGS.ACTIVATE}
          </Button>
        </>
      }
    >
      <Typography sx={{ pt: "1.5rem", pb: "1.5rem" }}>
        {STRINGS.ARE_YOU_SURE}
      </Typography>
    </GeneralDialogComponent>
  );
}

interface Props {
  user: User;
  handleClose: () => void;
}
