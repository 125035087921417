import { Delete } from "@mui/icons-material";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import { LiveClass, Session } from "../../../../interfaces";
import SessionService from "../../../../services/session.service";

export default function ViewSessionDialog(props: Props) {
  const STRINGS = {
    SESSIONS: "Sessions",
    DISMISS: "Dismiss",
    LIVE_CLASS_HAS_NO_GROUP: "Live Class Has No Sessions",
    CANCEL: "Cancel",
    CONFIRM: "Confirm",
    SUCCESS_REMOVE_GROUP: "Successfully Removed Group",
    DELETE_SESSION: "Delete Session",
    DELETE: "Delete",
    SUCCESS_DELETE_SESSION: "Successfully deleted session",
    FAILED_DELETE_SESSION: "Successfully deleted session",
  };

  const { liveClass, handleClose } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [sessions, setSessions] = useState<Session[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState("");

  const handleDelete = async () => {
    try {
      await SessionService.deleteSession(deleting);
      enqueueSnackbar(STRINGS.SUCCESS_DELETE_SESSION, { variant: "success" });
      handleClose();
    } catch (e: any) {
      enqueueSnackbar(e.message || STRINGS.FAILED_DELETE_SESSION, {
        variant: "error",
      });
    }
  };

  const call = async () => {
    setLoading(true);
    const sessions = await SessionService.readAllClassSession(liveClass.lcId);
    setSessions(sessions);
    setLoading(false);
  };

  useEffect(() => {
    call();
  }, []);

  return (
    <GeneralDialogComponent
      open
      onClose={handleClose}
      title={`${liveClass.lcTitle} ${STRINGS.SESSIONS}`}
      actions={<Button onClick={handleClose}>{STRINGS.DISMISS}</Button>}
    >
      <List>
        {!loading ? (
          sessions.length ? (
            sessions.map((session) => (
              <ListItem
                key={session.sessionId}
                secondaryAction={
                  <IconButton onClick={() => setDeleting(session.sessionId)}>
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={
                    <Link
                      to={`/live-class/${liveClass.lcId}/session/${session.sessionId}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography variant="body1" color="textPrimary">
                        {session.sessionTitle}
                      </Typography>
                    </Link>
                  }
                  secondary={`${moment(session.sessionStartDateTs).format(
                    "DD MMM y, HH:mm:ss A"
                  )}${" - "}${moment(session.sessionEndDateTs).format(
                    "DD MMM y, HH:mm:ss A"
                  )}`}
                />
              </ListItem>
            ))
          ) : (
            <Typography variant="body1" color="textSecondary">
              {STRINGS.LIVE_CLASS_HAS_NO_GROUP}
            </Typography>
          )
        ) : (
          <ListItem>
            <ListItemText
              primary={<Skeleton variant="text" height={16} width={200} />}
              secondary={<Skeleton variant="text" height={14} width={150} />}
            />
          </ListItem>
        )}
      </List>

      <GeneralDialogComponent
        open={Boolean(deleting)}
        onClose={() => setDeleting("")}
        title={STRINGS.DELETE_SESSION}
        actions={
          <>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setDeleting("")}
            >
              {STRINGS.CANCEL}
            </Button>
            <Button variant="contained" color="error" onClick={handleDelete}>
              {STRINGS.DELETE}
            </Button>
          </>
        }
      >
        {STRINGS.DELETE}{" "}
        {
          sessions.find((session) => session.sessionId === deleting)
            ?.sessionTitle
        }
        {"?"}
      </GeneralDialogComponent>
    </GeneralDialogComponent>
  );
}

interface Props {
  liveClass: LiveClass;
  handleClose: () => void;
}
