import React from "react";
import { useRouteMatch } from "react-router-dom";
import BreadcrumbsComponent from "../../../components/Breadcrumbs";
import AppContainer from "../../../containers/AppContainer";
import LiveClassTable from "./LiveClassTable";

const LiveClassPage = () => {
  const { url } = useRouteMatch();
  return (
    <AppContainer headerTitle={"Live Classes"}>
      <BreadcrumbsComponent url={url} />
      <LiveClassTable />
    </AppContainer>
  );
};

export default LiveClassPage;
