import { Add } from "@mui/icons-material";
import { Box, Button, Grid, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, HTMLInputTypeAttribute, useState } from "react";
import GeneralDialogComponent from "../../../components/GeneralDialogComponent";
import { REGEX } from "../../../constants/regex.constant";
import {
  Group,
  GroupErrors,
  GroupKeys,
  MakeTextField,
  MakeTextFields,
} from "../../../interfaces";
import GroupService from "../../../services/group.service";
import { checkTextFieldError } from "../../../utils/checkTextField";
import cleanValues from "../../../utils/cleanValues";
import { convertTitleToId } from "../../../utils/convertString";

const defaultGroup: Group = {
  groupId: "",
  groupTitle: "",
  totalSlots: 0,
  slotsAvailable: 0,
  createdAt: 0,
  updatedAt: 0,
};

const AddGroup = (props: Props) => {
  const STRINGS = {
    ADD_GROUP: "Add Group",
    ADD: "Add",
    SUCCESS_ADD_GROUP: "Successfully Added Group!",
  };

  const { onRefresh } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState<Group>(defaultGroup);
  const [errors, setErrors] = useState<GroupErrors>({} as GroupErrors);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setForm(defaultGroup);
    setOpen(false);
  };

  const makeTextField = (
    label: string,
    type: HTMLInputTypeAttribute | "textarea" | "autocomplete",
    key: GroupKeys,
    pattern: RegExp,
    required: boolean = false,
    disabled: boolean = false
  ): MakeTextField<Group> => ({
    label,
    type: type === "autocomplete" ? "autocomplete" : type,
    key,
    pattern,
    required,
    disabled,
    value: form[key],
    error: errors[key],
    helperText: errors[key]
      ? required && !form[key]
        ? "This field is required"
        : `Invalid ${label}`
      : "",
    name: key,
    multiline: type === "textarea",
    rows: 4,
  });

  const textFields: MakeTextFields<Group> = {
    createdAt: makeTextField(
      "Created At",
      "number",
      "createdAt",
      REGEX.NUMBER,
      true,
      true
    ),
    updatedAt: makeTextField(
      "Created At",
      "number",
      "createdAt",
      REGEX.NUMBER,
      true,
      true
    ),
    groupTitle: makeTextField(
      "Group Title",
      "text",
      "groupTitle",
      REGEX.ANY,
      true
    ),
    totalSlots: makeTextField(
      "Total Slots",
      "number",
      "totalSlots",
      REGEX.NUMBER,
      true
    ),
    groupId: makeTextField(
      "Group ID",
      "text",
      "groupId",
      REGEX.ANY,
      true,
      true
    ),
    slotsAvailable: makeTextField(
      "Slots Available",
      "number",
      "slotsAvailable",
      REGEX.NUMBER,
      true,
      true
    ),
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDisableSubmit(false);

    const { name, value } = e.target;
    let newForm = {} as Group;

    switch (name) {
      case "groupTitle":
        newForm = {
          ...form,
          groupId: convertTitleToId(value),
          groupTitle: value,
        };
        setForm(newForm);
        break;
      case "totalSlots":
        newForm = {
          ...form,
          totalSlots: Math.abs(+value),
          slotsAvailable: Math.abs(+value),
        };
        setForm(newForm);
        break;
      default:
        break;
    }

    const error: boolean = checkTextFieldError(
      textFields[name as keyof Group],
      newForm
    );
    setErrors({ ...errors, [name]: error });
  };

  const handleSubmit = async () => {
    setDisableSubmit(true);

    const massagedForm: Group = cleanValues<Group>({
      ...form,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
    });

    // validate form
    const hasError = Object.values(textFields).some((textField) => {
      const { name, label } = textField;
      const error: boolean = checkTextFieldError(textField, massagedForm);
      if (error) {
        setDisableSubmit(false);
        setErrors({ ...errors, [name]: error });
        enqueueSnackbar(`Invalid ${label}`, { variant: "error" });
      }
      return error;
    });
    if (hasError) return;

    if (massagedForm.totalSlots < massagedForm.slotsAvailable) {
      setDisableSubmit(false);
      enqueueSnackbar("Total Slots must be more than available slots!", {
        variant: "error",
      });
      return;
    }

    try {
      await GroupService.createGroup(massagedForm);
      setForm(defaultGroup);
      enqueueSnackbar(STRINGS.SUCCESS_ADD_GROUP, { variant: "success" });
      handleClose();
      onRefresh();
    } catch (e: any) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <Box width="100%" display="flex" justifyContent="flex-end" my={2}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={handleOpen}
      >
        {STRINGS.ADD_GROUP}
      </Button>
      <GeneralDialogComponent
        open={open}
        onClose={handleClose}
        title={STRINGS.ADD_GROUP}
        actions={
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={disableSubmit}
          >
            {STRINGS.ADD}
          </Button>
        }
      >
        <Grid container>
          {Object.values(textFields)
            .slice(2)
            .map((textField) => (
              <Grid item xs={12} key={textField.key}>
                <TextField
                  {...textField}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            ))}
        </Grid>
      </GeneralDialogComponent>
    </Box>
  );
};

interface Props {
  onRefresh: () => void;
}

export default AddGroup;
