import React, {
  ChangeEvent,
  HTMLInputTypeAttribute,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { useSnackbar } from "notistack";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { REGEX } from "../../../../constants/regex.constant";
import {
  User,
  UserKeys,
  UserErrors,
  MakeTextField,
  MakeTextFields,
  UserDetail,
} from "../../../../interfaces";
import { AutoCompleteOption } from "../../../../interfaces/TextField.interface";
import UserService from "../../../../services/user.service";
import { useAuth } from "../../../../utils/providers/AuthProvider";
import UserProfilePhoto from "./UserProfilePhoto";
import { make } from "../../../../utils/makeAutoCompleteOption";
import { checkTextFieldError } from "../../../../utils/checkTextField";

const UserDetailsForm = (props: Props) => {
  const STRINGS = {
    MAIN_DETAILS: "Main Details",
    OTHER_DETAILS: "Other Details",
    ADD_PHOTO: "+",
    SUBMIT: "Submit",
    SUCCESS_UPDATE_USER: "Successfully updated User!",
    ERROR_UPDATE_USER: "Failed to update User!",
    IMAGE_UPLOADED: "Successfully uploaded image!",
  };

  const { updating } = props;
  const history = useHistory();
  const { uid }: any = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const [form, setForm] = useState<UserDetail>({} as UserDetail);
  const [options] = useState<AutoCompleteOptions>(autoCompleteOptions);
  const [errors, setErrors] = useState<UserErrors>({} as UserErrors);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const makeTextField = (
    label: string,
    type: HTMLInputTypeAttribute | "textarea" | "autocomplete",
    key: UserKeys,
    pattern: RegExp,
    required: boolean = false,
    disabled: boolean = false
  ): MakeTextField<UserDetail> => ({
    label,
    type: type === "autocomplete" ? "autocomplete" : type,
    key,
    pattern,
    required,
    disabled: disabled || !updating,
    value:
      type === "datetime-local"
        ? moment(form[key] as number).format("D MMM yyyy HH:mm:ss ")
        : form[key],
    error: errors[key],
    helperText: errors[key]
      ? required && !form[key]
        ? "This field is required"
        : `Invalid ${label}`
      : "",
    name: key,
    multiline: type === "textarea",
    rows: 4,
  });

  const textFields: MakeTextFields<UserDetail> = {
    classId: makeTextField("", "text", "classId", REGEX.ANY),
    userUid: makeTextField("User UID", "string", "userUid", REGEX.NAME, true),
    userPhoto: makeTextField("User Photo", "file", "userPhoto", REGEX.ANY),
    userType: makeTextField(
      "User Type",
      "autocomplete",
      "userType",
      REGEX.MALAYSIAN_PHONE
    ),

    // primary data
    userName: makeTextField("Name", "text", "userName", REGEX.NAME),
    full_name: makeTextField(
      "Full Name",
      "text",
      "full_name",
      REGEX.NAME,
      true
    ),
    email: makeTextField(
      "Email Address",
      "email",
      "email",
      REGEX.EMAIL,
      true,
      true
    ),
    mobile: makeTextField(
      "User Phone No.",
      "text",
      "mobile",
      REGEX.MALAYSIAN_PHONE
    ),
    userIC: makeTextField("User NRIC", "text", "userIC", REGEX.MALAYSIAN_NRIC),
    // secondary data
    userCountry: makeTextField(
      "User Country",
      "autocomplete",
      "userCountry",
      REGEX.WORDS
    ),
    userLang: makeTextField(
      "User Language",
      "autocomplete",
      "userLang",
      REGEX.WORDS
    ),
    userGender: makeTextField(
      "User Gender",
      "autocomplete",
      "userGender",
      REGEX.WORDS
    ),
    userAge: makeTextField("User Age", "number", "userAge", REGEX.NUMBER),
    userEthnicity: makeTextField(
      "User Ethnicity",
      "text",
      "userEthnicity",
      REGEX.WORDS
    ),
    userCity: makeTextField("User City", "text", "userCity", REGEX.WORDS),
    userAddress: makeTextField(
      "User Address",
      "textarea",
      "userAddress",
      REGEX.ADDRESS
    ),
    userBio: makeTextField("User Bio", "textarea", "userBio", REGEX.ANY),
    userPhoneSecondary: makeTextField(
      "User Secondary Phone No.",
      "text",
      "userPhoneSecondary",
      REGEX.MALAYSIAN_PHONE
    ),
    userEmailSecondary: makeTextField(
      "User Secondary Email Address",
      "text",
      "userEmailSecondary",
      REGEX.EMAIL
    ),
    emailVerified: makeTextField(
      "User Email Verified",
      "autocomplete",
      "emailVerified",
      REGEX.WORDS
    ),
    userEmailConsent: makeTextField(
      "User Email Consent",
      "autocomplete",
      "userEmailConsent",
      REGEX.WORDS
    ),
    userAgreement: makeTextField(
      "User Agreement",
      "autocomplete",
      "userAgreement",
      REGEX.NAME
    ),
    // course related data
    userBadge: makeTextField(
      "User Badge",
      "autocomplete",
      "userBadge",
      REGEX.NAME,
      false
    ),
    userStatus: makeTextField(
      "User Status",
      "autocomplete",
      "userStatus",
      REGEX.NAME,
      false,
      true
    ),
    // meta data
    userCreatedAt: makeTextField(
      "User Created At",
      "datetime-local",
      "userCreatedAt",
      REGEX.ANY,
      false,
      true
    ),
    userUpdatedAt: makeTextField(
      "User Updated At",
      "datetime-local",
      "userUpdatedAt",
      REGEX.ANY,
      false,
      true
    ),
    userUpdatedBy: makeTextField(
      "User Updated By",
      "text",
      "userUpdatedBy",
      REGEX.ANY,
      false,
      true
    ),
    lastLogin: makeTextField("Last Login", "text", "lastLogin", REGEX.NUMBER),
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDisableSubmit(false);
    const { required, value } = e.target;
    const name = e.target.name as UserKeys;
    setForm({ ...form, [name]: value });

    // this is to check if the text field has error
    // 1. if field is '', return TRUE if field is required
    // 2. if field has value, return TRUE if pattern is wrong
    const pattern = textFields[name].pattern;
    const error: boolean = !value
      ? required
      : !new RegExp(pattern)?.test(value);
    setErrors({ ...errors, [name]: error });
  };

  const handleAutoCompleteChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: AutoCompleteOption | null,
    name: UserKeys
  ) => {
    e.preventDefault();
    setDisableSubmit(false);
    setForm({ ...form, [name]: newValue?.value });
  };

  const handleSubmit = async () => {
    setDisableSubmit(true);

    const massagedForm: UserDetail = {
      ...form,
      userCreatedAt: moment(form.userCreatedAt).valueOf(),
      userUpdatedAt: moment().valueOf(),
      userUpdatedBy: user?.email || "",
    };

    // revalidate form
    const hasError = Object.values(massagedForm).some((textField) => {
      const { name, label } = textField;
      const error: boolean = checkTextFieldError(textField, form);
      if (error) {
        setDisableSubmit(false);
        setErrors({ ...errors, [name]: error });
        enqueueSnackbar(`Invalid ${label}`, { variant: "error" });
      }
      return error;
    });
    if (hasError) return;

    console.table(form);

    try {
      await UserService.updateUser(massagedForm);
      console.log("updated");
      enqueueSnackbar(STRINGS.SUCCESS_UPDATE_USER, { variant: "success" });
      history.push("/user");
    } catch (error) {
      setDisableSubmit(false);
      console.error("error:", error);
      enqueueSnackbar(STRINGS.ERROR_UPDATE_USER, { variant: "error" });
    }
  };

  useEffect(() => {
    (async () => {
      const user = await UserService.readOneUser(uid);
      if (!user) return history.push("/not-found");

      const userMeta = await UserService.readOneUserMeta(uid);
      const mergedData = { ...user, ...userMeta };
      setForm(mergedData);

      console.table(mergedData);

      // dynamic countries
      // const contries = await axios.get('https://restcountries.com/v3.1/all');
      // const userCountry: AutoCompleteOption[] = contries.data.map((country: any) => ({
      //   label: country.name.common
      // }))
      // setOptions({ ...options, userCountry });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs={12} lg={8}>
      {/* main data */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ marginTop: "3rem" }}>
            {STRINGS.MAIN_DETAILS}
          </Typography>
        </Grid>

        {/* profile photo */}
        <UserProfilePhoto
          form={form}
          setForm={setForm}
          userPhotoTextField={textFields.userPhoto}
          updating={updating}
        />

        {Object.values(textFields)
          .slice(4, 9)
          .map((textField) => (
            <Grid item xs={12} md={6} key={textField.key}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                onChange={handleChange}
                {...textField}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          ))}
      </Grid>

      <Divider sx={{ my: "2rem" }} />

      {/* secondary data */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">{STRINGS.OTHER_DETAILS}</Typography>
        </Grid>
        {Object.values(textFields)
          .slice(9)
          .map((textField, index) => (
            <Grid item xs={12} md={6} key={textField.key}>
              {textField.type === "autocomplete" ? (
                <Autocomplete
                  disablePortal
                  fullWidth
                  disabled={textField.disabled}
                  options={options[textField.key as AutoCompleteOptionKeys]}
                  getOptionLabel={(option) => option.title}
                  isOptionEqualToValue={(option) =>
                    option.value === form[textField.key]
                  }
                  value={options[textField.key as AutoCompleteOptionKeys]?.find(
                    (option) => option.value === form[textField.key]
                  )}
                  onChange={(e, newValue) =>
                    handleAutoCompleteChange(e, newValue, textField.name)
                  }
                  // random key needs to be used in order to rerender the autocomplete field after async event
                  key={`autocomplete-${textField.key}-${Math.random() * 1000}`}
                  renderInput={(params) => (
                    <TextField margin="normal" {...params} {...textField} />
                  )}
                />
              ) : (
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  {...textField}
                />
              )}
            </Grid>
          ))}
      </Grid>

      {/* submit button */}
      {updating ? (
        <Box width="100%" mt="1rem" display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={handleSubmit}
            // find if there is any errors
            disabled={
              Object.values(errors).find((error) => error === true) ||
              disableSubmit
            }
          >
            {STRINGS.SUBMIT}
          </Button>
        </Box>
      ) : null}
    </Grid>
  );
};

interface Props {
  updating: boolean;
}

interface AutoCompleteOptions {
  userCountry: AutoCompleteOption[];
  userLang: AutoCompleteOption[];
  userGender: AutoCompleteOption[];
  emailVerified: AutoCompleteOption[];
  userAgreement: AutoCompleteOption[];
  userType: AutoCompleteOption[];
  emailConsent: AutoCompleteOption[];
  userBadge: AutoCompleteOption[];
  userStatus: AutoCompleteOption[];
}

type AutoCompleteOptionKeys = keyof AutoCompleteOptions;

const autoCompleteOptions: AutoCompleteOptions = {
  userCountry: [make("Malaysia"), make("Indonesia"), make("Others")],
  userLang: [make("English"), make("Malay")],
  userGender: [make("Male"), make("Female"), make("Others")],
  emailVerified: [make("Verified", true), make("Not Verified", false)],
  userAgreement: [make("Agreed", true), make("Disagree", false)],
  userType: [make("User")],
  emailConsent: [make("Yes", true), make("No", false)],
  userBadge: [make("Good"), make("Bad")],
  userStatus: [make("active"), make("inactive")],
};

export default UserDetailsForm;
