import { Box, Typography } from "@mui/material";
import React from "react";
import AppContainer from "../../containers/AppContainer";

export default function NotFoundPage() {
  const STRINGS = {
    TITLE: "404 Not Found",
    DESCRIPTION: "This page may have been removed or does not exist",
  };

  return (
    <AppContainer headerTitle={STRINGS.TITLE}>
      <Box
        minHeight="50vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h2" color="textSecondary">
          {STRINGS.TITLE}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {STRINGS.DESCRIPTION}
        </Typography>
      </Box>
    </AppContainer>
  );
}
