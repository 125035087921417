import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../layouts/Router/PrivateRoute";
import GroupList from "./components/GroupList";
import GroupDetailsPage from "./GroupDetails";

const GroupPage = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path} component={GroupList} />
      <PrivateRoute
        exact
        path={`${path}/:groupId`}
        component={GroupDetailsPage}
      />
    </Switch>
  );
};

export default GroupPage;
