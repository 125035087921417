import * as React from 'react';
import {FC, ReactNode, useState} from 'react';
import Box from '@mui/material/Box';
import AppBarComponent from "../../components/AppBarComponent";
import FooterComponent from "../../components/FooterComponent";
import SideBarComponent from "../../components/SideBar/SideBarComponent";
import {SiteContainerStyle} from "../../constants/style-constants";

interface Props {
  children: ReactNode,
  headerTitle: string
}

const AppContainer: FC<Props> = ({children, headerTitle}) => {

  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  }

  return (
    <>
      <Box sx={{...SiteContainerStyle}}>
        <Box sx={{flexGrow: 1}}>
          <AppBarComponent title={headerTitle} handleDrawerToggle={handleDrawerToggle}/>
          <SideBarComponent open={open} handleDrawerToggle={handleDrawerToggle}/>
        </Box>
        <Box sx={{paddingTop: '8rem', marginLeft: '1rem', marginRight: '1rem', paddingBottom: '5rem'}}>
          {children}
        </Box>


      </Box>
      <FooterComponent/>
    </>
  );
}
export default AppContainer;
