import {
  LiveClassForm,
  LiveClass,
  UserProductLiveClass,
  ParentLiveClass,
  Group,
  Teacher,
  Course,
  Material,
  GroupUser,
  User,
  GroupUserObjectList,
} from "../interfaces";
import { db } from "./firebaseApp";
import {
  ref,
  set,
  get,
  remove,
  update,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";
import GroupService from "./group.service";

const LiveClassService = {
  async createDraft(data: LiveClass) {
    const lcRef = ref(
      db,
      `/sepContent/liveClass/lcDraft/lcDraftList/${data.lcId}`
    );
    return await set(lcRef, data);
  },

  async publishLiveClass(data: LiveClass) {
    const lcEnglishRef = ref(
      db,
      `/sepContent/liveClass/lcList/english/${data.lcId}`
    );
    const lcMalayRef = ref(
      db,
      `/sepContent/liveClass/lcList/malay/${data.lcId}`
    );
    await set(lcEnglishRef, data);
    await set(lcMalayRef, data);

    const { lcId, lcTitle, lcImage, lcStartDateTs, lcEndDateTs } = data;
    const groupUsers = await GroupService.readAllGroupUser(data.lcUserGroup);
    const userProduct: UserProductLiveClass = {
      lcId,
      lcTitle,
      lcImage,
      lcStartDateTs,
      lcEndDateTs,
    };
    return await this.addManyUserToClass(userProduct, groupUsers);
  },

  async addUserToClass(data: UserProductLiveClass, user: User) {
    const { full_name, userName, email, userUid } = user;

    const lcRef = ref(db, `/userProductList/liveClass/${userUid}/${data.lcId}`);
    const relationRef = ref(
      db,
      `/userProductRelation/liveClass/${data.lcId}/${userUid}`
    );

    const groupUser: GroupUser = {
      groupId: "",
      groupName: "",
      userName: full_name || userName,
      userEmail: email,
      userUid,
    };
    await set(lcRef, data);
    return await set(relationRef, groupUser);
  },

  async addManyUserToClass(data: UserProductLiveClass, users: GroupUser[]) {
    const { lcId, lcTitle, lcImage, lcStartDateTs, lcEndDateTs } = data;

    // user's individual product list
    const userProductList: {
      [path: string]: UserProductLiveClass;
    } = {};
    // live class list of users
    const userProductRelation: {
      [path: string]: GroupUser;
    } = {};
    const userProductListRef = ref(db, "/userProductList/liveClass/");
    const userProductRelationRef = ref(db, "/userProductRelation/liveClass");

    users.forEach((user) => {
      // add into users' individual product list
      userProductList[`${user.userUid}/${lcId}`] = {
        lcId,
        lcTitle,
        lcImage,
        lcStartDateTs,
        lcEndDateTs,
      };
      // add user under live class
      userProductRelation[`${lcId}/${user.userUid}`] = user;
    });

    await update(userProductListRef, userProductList);
    await update(userProductRelationRef, userProductRelation);
  },

  async readLiveClasses(): Promise<LiveClass[]> {
    const lcRef = query(
      ref(db, `/sepContent/liveClass/lcList/english`),
      orderByChild("lcStartDateTs")
    );
    const snapshot = await get(lcRef);
    const liveClasses: LiveClass[] = [];
    snapshot.forEach((snap) => {
      liveClasses.push(snap.val());
    });
    return liveClasses.reverse();
  },

  async readCourses(): Promise<Course[]> {
    const lcRef = ref(db, `/sepContent/course/courseList/english`);
    const snapshot = await get(lcRef);
    const courses: Course[] = [];
    snapshot.forEach((snap) => {
      courses.push(snap.val());
    });
    return courses;
  },

  async readParentLiveClasses(): Promise<ParentLiveClass[]> {
    const lcRef = ref(db, `/sepContent/liveClass/lpList/english`);
    const snapshot = await get(lcRef);
    const parentLiveClasses: ParentLiveClass[] = [];
    snapshot.forEach((snap) => {
      parentLiveClasses.push(snap.val());
    });
    return parentLiveClasses;
  },

  async readUserGroups(): Promise<Group[]> {
    const lcRef = ref(db, `/sepContent/liveClass/lcGroupList/english`);
    const snapshot = await get(lcRef);
    const userGroups: Group[] = [];
    snapshot.forEach((snap) => {
      userGroups.push(snap.val());
    });
    return userGroups;
  },

  async readTeachers(): Promise<Teacher[]> {
    const lcRef = ref(db, `/lcTeachers`);
    const snapshot = await get(lcRef);
    const teachers: Teacher[] = [];
    snapshot.forEach((snap) => {
      teachers.push(snap.val());
    });
    return teachers;
  },

  async readOneTeacher(teacherId: string): Promise<Teacher> {
    const lcRef = ref(db, `/lcTeachers/${teacherId}`);
    const snapshot = await get(lcRef);
    return snapshot.val();
  },

  async readMaterials(): Promise<Material[]> {
    const materialRef = ref(db, "/sepContent/liveClass/lcMaterialList/english");
    const snapshot = await get(materialRef);
    const materials: Material[] = [];
    snapshot.forEach((material) => {
      materials.push(material.val());
    });
    return materials;
  },

  async readOneLiveClass(id: string): Promise<LiveClass | LiveClassForm> {
    const lcRef = ref(db, `/sepContent/liveClass/lcList/english/${id}`);
    const snapshot = await get(lcRef);
    return snapshot.val();
  },

  async readLiveClassesHtml(): Promise<LiveClass[]> {
    const lcRef = ref(db, `/sepContent/liveClass/lcDetailHtml/english`);
    const snapshot = await get(lcRef);
    const liveClassesHtml: LiveClass[] = [];
    snapshot.forEach((snap) => {
      liveClassesHtml.push(snap.val());
    });
    return liveClassesHtml;
  },

  async updateLiveClass(data: LiveClass, oldGroup: string) {
    const lcEnglishRef = ref(
      db,
      `/sepContent/liveClass/lcList/english/${data.lcId}`
    );
    const lcMalayRef = ref(
      db,
      `/sepContent/liveClass/lcList/malay/${data.lcId}`
    );

    if (oldGroup === data.lcUserGroup) {
      await update(lcEnglishRef, data);
      return await update(lcMalayRef, data);
    }
    // delete users from previous group
    const oldGroupUsers = await GroupService.readAllGroupUser(oldGroup);
    await this.deleteManyUserFromClass(data.lcId, oldGroupUsers);

    // add new users to updated group
    const { lcId, lcTitle, lcImage, lcStartDateTs, lcEndDateTs } = data;
    const groupUsers = await GroupService.readAllGroupUser(data.lcUserGroup);
    const userProduct: UserProductLiveClass = {
      lcId,
      lcTitle,
      lcImage,
      lcStartDateTs,
      lcEndDateTs,
    };
    await this.addManyUserToClass(userProduct, groupUsers);

    // then update
    await update(lcEnglishRef, data);
    return await update(lcMalayRef, data);
  },

  async readClassUsers(lcId: string): Promise<GroupUser[]> {
    const lcRef = ref(db, `/userProductRelation/liveClass/${lcId}`);
    const snapshot = await get(lcRef);
    const userList: GroupUser[] = [];
    snapshot.forEach((user) => {
      userList.push(user.val());
    });
    return userList;
  },

  async readClassUsersAsObject(lcId: string): Promise<GroupUserObjectList> {
    const lcRef = ref(db, `/userProductRelation/liveClass/${lcId}`);
    const snapshot = await get(lcRef);
    return snapshot.val();
  },

  async readGroupClasses(groupId: string): Promise<LiveClass[]> {
    const lcQuery = query(
      ref(db, "/sepContent/liveClass/lcList/english/"),
      orderByChild("lcUserGroup"),
      equalTo(groupId)
    );
    const snapshot = await get(lcQuery);
    const liveClasses: LiveClass[] = [];
    snapshot.forEach((snap) => {
      liveClasses.push(snap.val());
    });
    return liveClasses;
  },

  async readUserProductLiveClasses(
    uid: string
  ): Promise<UserProductLiveClass[]> {
    const lcRef = ref(db, `/userProductList/liveClass/${uid}`);
    const snapshot = await get(lcRef);
    const liveClasses: UserProductLiveClass[] = [];
    snapshot.forEach((liveClass) => {
      liveClasses.push(liveClass.val());
    });
    return liveClasses;
  },

  async checkForDrafts(user: string): Promise<boolean> {
    const lcRef = ref(db, `/sepContent/liveClass/lcDraft/lcDraftList`);
    const snapshot = await get(lcRef);
    let draft: boolean = false;
    snapshot.forEach((snap) => {
      draft = snap.val().updatedBy === user;
      if (draft) return draft;
    });
    return draft;
  },

  async readAllDrafts(): Promise<LiveClass[]> {
    const draftRef = ref(db, `/sepContent/liveClass/lcDraft/lcDraftList`);
    const snapshot = await get(draftRef);
    const draftList: LiveClass[] = [];
    snapshot.forEach((snap) => {
      draftList.push(snap.val());
    });
    return draftList;
  },

  async readDrafts(user: string): Promise<LiveClassForm[]> {
    const lcRef = ref(db, `/sepContent/liveClass/lcDraft/lcDraftList`);
    const snapshot = await get(lcRef);
    const draftList: LiveClassForm[] = [];
    snapshot.forEach((snap) => {
      draftList.push(snap.val());
    });
    const draftForm = draftList.filter((entry: LiveClassForm) =>
      entry.updatedBy.includes(user)
    );
    return draftForm;
  },

  async deleteManyUserFromClass(lcId: string, users: GroupUser[]) {
    // remove old users
    // user's individual product list
    const userProductList: {
      [path: string]: null;
    } = {};
    const userProductListRef = ref(db, "/userProductList/liveClass/");
    const userProductRelationRef = ref(
      db,
      `/userProductRelation/liveClass/${lcId}`
    );

    users.forEach((user) => {
      // add into users' individual product list
      userProductList[`${user.userUid}/${lcId}`] = null;
    });

    await update(userProductListRef, userProductList);
    await remove(userProductRelationRef);
  },

  async deleteDraft(id: string) {
    const lcRef = ref(db, `/sepContent/liveClass/lcDraft/lcDraftList/${id}`);
    return await remove(lcRef);
  },

  async deleteLiveClass(id: string) {
    const lcEnglishRef = ref(db, `/sepContent/liveClass/lcList/english/${id}`);
    const lcMalayRef = ref(db, `/sepContent/liveClass/lcList/malay/${id}`);

    await remove(lcEnglishRef);
    return await remove(lcMalayRef);
  },

  async deleteOneUserLiveClass(uid: string, lcId: string) {
    const lcRef = ref(db, `/userProductList/liveClass/${uid}/${lcId}`);
    return await remove(lcRef);
  },

  async deleteManyUserLiveClass(userUids: string[], lcId: string) {
    const lcRef = ref(db, `/userProductList/liveClass`);

    const users: any = {};
    userUids.forEach((uid) => {
      users[`${uid}/${lcId}`] = null;
    });

    return await update(lcRef, users);
  },
};

export default LiveClassService;
