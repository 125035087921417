import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import PrivateRoute from '../../layouts/Router/PrivateRoute'
import UserPage from './components'
import UserDetailsPage from './UserDetails'

const User = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path} component={UserPage} />
      <PrivateRoute path={`${path}/:uid`} component={UserDetailsPage} />
    </Switch>
  )
}

export default User
