import {Redirect, Route, RouteProps} from 'react-router';
import BackdropComponent from '../../components/BackDropComponent';
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../services/firebaseApp";

const PrivateRoute = (routeProps: RouteProps) => {
  // const {user, loading} = useAuth();
  const [user, loading, error] = useAuthState(auth);


  if (!Boolean(user)) return <Redirect to="/login"/>;

  if (user) return <Route {...routeProps} />;

  if (error) return <Redirect to="/login"/>;
  if (loading) return <BackdropComponent/>;

  return <Redirect to="/login"/>
}

export default PrivateRoute
